export const report_jamarat = [


  {
    "label": "4/128",
    "jamarat_level": {
      "id": 4,
      "name": "منشأة الجمرات - الطابق الثالث"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.878888816,
                21.420921402
              ],
              [
                39.879096827,
                21.421191739
              ],
              [
                39.879158233,
                21.421160508
              ],
              [
                39.879175854,
                21.421187768
              ],
              [
                39.879289537,
                21.421140291
              ],
              [
                39.879271737,
                21.421116067
              ],
              [
                39.879356969,
                21.421088174
              ],
              [
                39.879133826,
                21.420808605
              ],
              [
                39.879041548,
                21.420853886
              ],
              [
                39.878888816,
                21.420921402
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.87625141,
              21.421802125
            ],
            [
              39.876491467,
              21.421877436
            ],
            [
              39.8767719,
              21.421813819
            ],
            [
              39.877647736,
              21.421421995
            ],
            [
              39.877714105,
              21.421412566
            ],
            [
              39.877781102,
              21.421408612
            ],
            [
              39.877848211,
              21.421410166
            ],
            [
              39.877914917,
              21.421417215
            ],
            [
              39.877980707,
              21.421429704
            ],
            [
              39.878045079,
              21.421447538
            ],
            [
              39.878107535,
              21.421470579
            ],
            [
              39.878167082,
              21.421500593
            ],
            [
              39.878229436,
              21.421525102
            ],
            [
              39.87829402,
              21.421543882
            ],
            [
              39.878360234,
              21.421556754
            ],
            [
              39.878427463,
              21.421563603
            ],
            [
              39.878495084,
              21.421564364
            ],
            [
              39.878562469,
              21.421559028
            ],
            [
              39.878947076,
              21.421413589
            ],
            [
              39.879478885,
              21.421192642
            ],
            [
              39.8793627,
              21.420956928
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.871568415,
              21.4234645
            ],
            [
              39.871627628,
              21.423447019
            ],
            [
              39.871671655,
              21.423434021
            ],
            [
              39.871823746,
              21.42338912
            ],
            [
              39.871893537,
              21.423368516
            ],
            [
              39.871967855,
              21.423346576
            ],
            [
              39.871992527,
              21.423339292
            ],
            [
              39.872049805,
              21.423311717
            ],
            [
              39.872067733,
              21.423303087
            ],
            [
              39.872203813,
              21.42317567
            ],
            [
              39.872655991,
              21.422752278
            ],
            [
              39.873208853,
              21.422425689
            ],
            [
              39.873731174,
              21.422117139
            ],
            [
              39.874549428,
              21.421633766
            ],
            [
              39.874977146,
              21.421409652
            ],
            [
              39.875340498,
              21.421219263
            ],
            [
              39.875842422,
              21.420956262
            ],
            [
              39.875910078,
              21.420920811
            ],
            [
              39.87609528,
              21.420823767
            ],
            [
              39.876114649,
              21.420813618
            ],
            [
              39.87615925,
              21.420790248
            ],
            [
              39.876312561,
              21.420709914
            ],
            [
              39.876442142,
              21.420642015
            ],
            [
              39.876466016,
              21.420629504
            ],
            [
              39.876524777,
              21.420598714
            ],
            [
              39.876692979,
              21.420520113
            ],
            [
              39.876701368,
              21.420516325
            ],
            [
              39.876729714,
              21.420503613
            ],
            [
              39.876758761,
              21.420490732
            ],
            [
              39.876765984,
              21.420487552
            ],
            [
              39.876767179,
              21.420487026
            ],
            [
              39.876770024,
              21.420485776
            ],
            [
              39.876771884,
              21.42048496
            ],
            [
              39.876775791,
              21.420483334
            ],
            [
              39.877292592,
              21.420363117
            ],
            [
              39.87731535,
              21.420356672
            ],
            [
              39.877338944,
              21.420353921
            ],
            [
              39.877362695,
              21.420354942
            ],
            [
              39.87738592,
              21.420359708
            ],
            [
              39.877407951,
              21.42036808
            ],
            [
              39.877428154,
              21.420379818
            ],
            [
              39.877445948,
              21.420394584
            ],
            [
              39.87758,
              21.420516905
            ],
            [
              39.878651471,
              21.419971068
            ],
            [
              39.878867778,
              21.419871095
            ],
            [
              39.879086836,
              21.419776518
            ],
            [
              39.879308491,
              21.419687404
            ],
            [
              39.879532587,
              21.419603816
            ],
            [
              39.879758967,
              21.419525811
            ],
            [
              39.879987473,
              21.419453445
            ],
            [
              39.880217945,
              21.419386769
            ],
            [
              39.880426222,
              21.419336286
            ],
            [
              39.880797151,
              21.419482906
            ],
            [
              39.880872241,
              21.419524673
            ],
            [
              39.88087729,
              21.41957088
            ],
            [
              39.880889496,
              21.4196159
            ],
            [
              39.880908595,
              21.419658768
            ],
            [
              39.88093418,
              21.419698562
            ],
            [
              39.880965699,
              21.419734429
            ],
            [
              39.881002477,
              21.419765597
            ],
            [
              39.881043395,
              21.419783979
            ],
            [
              39.881086712,
              21.419796656
            ],
            [
              39.881131526,
              21.419803365
            ],
            [
              39.881176905,
              21.419803967
            ],
            [
              39.881221904,
              21.41979845
            ],
            [
              39.881661912,
              21.419667526
            ],
            [
              39.881704939,
              21.419666317
            ],
            [
              39.881747601,
              21.419671679
            ],
            [
              39.881788763,
              21.419683471
            ],
            [
              39.881827328,
              21.419701377
            ],
            [
              39.881862267,
              21.41972492
            ],
            [
              39.88189265,
              21.419753473
            ],
            [
              39.881917665,
              21.419786275
            ],
            [
              39.881936646,
              21.41982245
            ],
            [
              39.881949089,
              21.419861036
            ],
            [
              39.88195466,
              21.419901003
            ],
            [
              39.881953211,
              21.419941287
            ],
            [
              39.881944781,
              21.419980813
            ],
            [
              39.881929596,
              21.420018527
            ],
            [
              39.881908058,
              21.420053426
            ],
            [
              39.881880742,
              21.420084578
            ],
            [
              39.881848378,
              21.420111152
            ],
            [
              39.879437677,
              21.421109039
            ],
            [
              39.8793627,
              21.420956928
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "4/128",
      "name": "A5"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "38ed3f9d-6384-455b-bf00-1ae04fe449f6",
      "number": 10077,
      "total_pilgrims": 1692,
      "name": "شركة السلام المتحدة"
    },
    "schedule": [
      {
        "batch": {
          "id": "099836d8-1043-4997-a2f7-1ca7ace3c6d2",
          "code": "INT1007700412809",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "21:40:00",
        "stoning_day": 12,
        "stoning_time": "22:00:00"
      },
      {
        "batch": {
          "id": "a5296fa7-d6ca-405f-9478-80555608e94c",
          "code": "INT1007700412808",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "08:45:00",
        "stoning_day": 12,
        "stoning_time": "09:05:00"
      },
      {
        "batch": {
          "id": "d8a45f7b-25cb-4acc-b422-3907f1ed7c5c",
          "code": "INT1007700412807",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "17:45:00",
        "stoning_day": 12,
        "stoning_time": "18:05:00"
      },
      {
        "batch": {
          "id": "7fd920ad-62ac-46c3-a7e7-06989766d198",
          "code": "INT1007700412806",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "04:50:00",
        "stoning_day": 12,
        "stoning_time": "05:10:00"
      },
      {
        "batch": {
          "id": "7f8a4f77-4738-4a3e-b0a6-dbd27a843427",
          "code": "INT1007700412805",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "10:50:00",
        "stoning_day": 12,
        "stoning_time": "11:10:00"
      },
      {
        "batch": {
          "id": "d743a99f-7062-45c1-9ef7-36305cf93e16",
          "code": "INT1007700412803",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "19:50:00",
        "stoning_day": 12,
        "stoning_time": "20:10:00"
      },
      {
        "batch": {
          "id": "7817a066-2fa9-47b0-92ea-470ba6ecda23",
          "code": "INT1007700412804",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "06:55:00",
        "stoning_day": 12,
        "stoning_time": "07:15:00"
      },
      {
        "batch": {
          "id": "fa53d71a-285c-4c82-9087-898145a0bb8c",
          "code": "INT1007700412802",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:55:00",
        "stoning_day": 12,
        "stoning_time": "16:15:00"
      },
      {
        "batch": {
          "id": "c3d1bf7c-b94d-4ea2-ad19-9563e27f202a",
          "code": "INT1007700412801",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "19:55:00",
        "stoning_day": 12,
        "stoning_time": "20:15:00"
      },
      {
        "batch": {
          "id": "099836d8-1043-4997-a2f7-1ca7ace3c6d2",
          "code": "INT1007700412809",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "04:45:00",
        "stoning_day": 13,
        "stoning_time": "05:05:00"
      },
      {
        "batch": {
          "id": "d8a45f7b-25cb-4acc-b422-3907f1ed7c5c",
          "code": "INT1007700412807",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "09:45:00",
        "stoning_day": 13,
        "stoning_time": "10:05:00"
      },
      {
        "batch": {
          "id": "7f8a4f77-4738-4a3e-b0a6-dbd27a843427",
          "code": "INT1007700412805",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "04:50:00",
        "stoning_day": 13,
        "stoning_time": "05:10:00"
      },
      {
        "batch": {
          "id": "d743a99f-7062-45c1-9ef7-36305cf93e16",
          "code": "INT1007700412803",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "08:50:00",
        "stoning_day": 13,
        "stoning_time": "09:10:00"
      },
      {
        "batch": {
          "id": "c3d1bf7c-b94d-4ea2-ad19-9563e27f202a",
          "code": "INT1007700412801",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "11:50:00",
        "stoning_day": 13,
        "stoning_time": "12:10:00"
      },
      {
        "batch": {
          "id": "7f8a4f77-4738-4a3e-b0a6-dbd27a843427",
          "code": "INT1007700412805",
          "pilgrim": 188
        },
        "dispatching_day": 9,
        "dispatching_time": "22:40:00",
        "stoning_day": 9,
        "stoning_time": "23:00:00"
      },
      {
        "batch": {
          "id": "099836d8-1043-4997-a2f7-1ca7ace3c6d2",
          "code": "INT1007700412809",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:40:00",
        "stoning_day": 10,
        "stoning_time": "11:00:00"
      },
      {
        "batch": {
          "id": "a5296fa7-d6ca-405f-9478-80555608e94c",
          "code": "INT1007700412808",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "16:40:00",
        "stoning_day": 10,
        "stoning_time": "17:00:00"
      },
      {
        "batch": {
          "id": "d8a45f7b-25cb-4acc-b422-3907f1ed7c5c",
          "code": "INT1007700412807",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "21:40:00",
        "stoning_day": 10,
        "stoning_time": "22:00:00"
      },
      {
        "batch": {
          "id": "7fd920ad-62ac-46c3-a7e7-06989766d198",
          "code": "INT1007700412806",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:45:00",
        "stoning_day": 10,
        "stoning_time": "14:05:00"
      },
      {
        "batch": {
          "id": "7817a066-2fa9-47b0-92ea-470ba6ecda23",
          "code": "INT1007700412804",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "18:45:00",
        "stoning_day": 10,
        "stoning_time": "19:05:00"
      },
      {
        "batch": {
          "id": "d743a99f-7062-45c1-9ef7-36305cf93e16",
          "code": "INT1007700412803",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:50:00",
        "stoning_day": 10,
        "stoning_time": "11:10:00"
      },
      {
        "batch": {
          "id": "fa53d71a-285c-4c82-9087-898145a0bb8c",
          "code": "INT1007700412802",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "19:50:00",
        "stoning_day": 10,
        "stoning_time": "20:10:00"
      },
      {
        "batch": {
          "id": "c3d1bf7c-b94d-4ea2-ad19-9563e27f202a",
          "code": "INT1007700412801",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:55:00",
        "stoning_day": 10,
        "stoning_time": "14:15:00"
      },
      {
        "batch": {
          "id": "099836d8-1043-4997-a2f7-1ca7ace3c6d2",
          "code": "INT1007700412809",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "08:40:00",
        "stoning_day": 11,
        "stoning_time": "09:00:00"
      },
      {
        "batch": {
          "id": "a5296fa7-d6ca-405f-9478-80555608e94c",
          "code": "INT1007700412808",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "19:40:00",
        "stoning_day": 11,
        "stoning_time": "20:00:00"
      },
      {
        "batch": {
          "id": "d8a45f7b-25cb-4acc-b422-3907f1ed7c5c",
          "code": "INT1007700412807",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "09:45:00",
        "stoning_day": 11,
        "stoning_time": "10:05:00"
      },
      {
        "batch": {
          "id": "7fd920ad-62ac-46c3-a7e7-06989766d198",
          "code": "INT1007700412806",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "16:45:00",
        "stoning_day": 11,
        "stoning_time": "17:05:00"
      },
      {
        "batch": {
          "id": "7f8a4f77-4738-4a3e-b0a6-dbd27a843427",
          "code": "INT1007700412805",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "22:45:00",
        "stoning_day": 11,
        "stoning_time": "23:05:00"
      },
      {
        "batch": {
          "id": "7817a066-2fa9-47b0-92ea-470ba6ecda23",
          "code": "INT1007700412804",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "09:50:00",
        "stoning_day": 11,
        "stoning_time": "10:10:00"
      },
      {
        "batch": {
          "id": "d743a99f-7062-45c1-9ef7-36305cf93e16",
          "code": "INT1007700412803",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "15:50:00",
        "stoning_day": 11,
        "stoning_time": "16:10:00"
      },
      {
        "batch": {
          "id": "fa53d71a-285c-4c82-9087-898145a0bb8c",
          "code": "INT1007700412802",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "21:50:00",
        "stoning_day": 11,
        "stoning_time": "22:10:00"
      },
      {
        "batch": {
          "id": "c3d1bf7c-b94d-4ea2-ad19-9563e27f202a",
          "code": "INT1007700412801",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "08:55:00",
        "stoning_day": 11,
        "stoning_time": "09:15:00"
      }
    ]
  },
  {
    "label": "15/716",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.902661808,
                21.394993329
              ],
              [
                39.902524664,
                21.394928218
              ],
              [
                39.902337116,
                21.394839469
              ],
              [
                39.902322842,
                21.394837351
              ],
              [
                39.90230839,
                21.394837429
              ],
              [
                39.902294143,
                21.394839703
              ],
              [
                39.902280479,
                21.394844112
              ],
              [
                39.90226776,
                21.394850539
              ],
              [
                39.90225123,
                21.394865255
              ],
              [
                39.901816558,
                21.395652102
              ],
              [
                39.902158268,
                21.395815593
              ],
              [
                39.902217155,
                21.395698851
              ],
              [
                39.902310364,
                21.395738841
              ],
              [
                39.902244463,
                21.395855905
              ],
              [
                39.902469806,
                21.395967691
              ],
              [
                39.902622125,
                21.396075183
              ],
              [
                39.902883389,
                21.395747994
              ],
              [
                39.903123903,
                21.395403275
              ],
              [
                39.90315745,
                21.395349047
              ],
              [
                39.903168208,
                21.395311784
              ],
              [
                39.903162862,
                21.395269583
              ],
              [
                39.903137475,
                21.395231791
              ],
              [
                39.902661808,
                21.394993329
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.902185424,
              21.394966473
            ],
            [
              39.902094149,
              21.394926641
            ],
            [
              39.901872258,
              21.39532798
            ],
            [
              39.901799526,
              21.395454583
            ],
            [
              39.901642444,
              21.395738265
            ],
            [
              39.900733189,
              21.395305239
            ],
            [
              39.900645156,
              21.395267466
            ],
            [
              39.900827113,
              21.394863092
            ],
            [
              39.900985608,
              21.394486955
            ],
            [
              39.900989774,
              21.394468069
            ],
            [
              39.900990452,
              21.394448796
            ],
            [
              39.900987622,
              21.394429696
            ],
            [
              39.900981365,
              21.394411323
            ],
            [
              39.900971864,
              21.394394215
            ],
            [
              39.900959396,
              21.394378869
            ],
            [
              39.900944323,
              21.394365731
            ],
            [
              39.900927085,
              21.394355183
            ],
            [
              39.900908184,
              21.394347534
            ],
            [
              39.900882573,
              21.394342337
            ],
            [
              39.900858304,
              21.394333079
            ],
            [
              39.900836105,
              21.394320037
            ],
            [
              39.900816644,
              21.394303603
            ],
            [
              39.900800501,
              21.394284269
            ],
            [
              39.900788165,
              21.394262618
            ],
            [
              39.900780004,
              21.394239297
            ],
            [
              39.900776261,
              21.394215006
            ],
            [
              39.900777052,
              21.394190475
            ],
            [
              39.900875752,
              21.393907166
            ],
            [
              39.901094598,
              21.39327897
            ],
            [
              39.901051131,
              21.393243853
            ],
            [
              39.900712275,
              21.393049394
            ],
            [
              39.90024065,
              21.392834932
            ],
            [
              39.900199523,
              21.392806376
            ],
            [
              39.900162763,
              21.392773005
            ],
            [
              39.900131006,
              21.392735397
            ],
            [
              39.900104804,
              21.392694204
            ],
            [
              39.900084613,
              21.392650143
            ],
            [
              39.900070783,
              21.392603978
            ],
            [
              39.90004127,
              21.392306418
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.89999929,
              21.392302749
            ],
            [
              39.900030179,
              21.392679303
            ],
            [
              39.900049641,
              21.392724269
            ],
            [
              39.900075502,
              21.392766311
            ],
            [
              39.900107276,
              21.392804636
            ],
            [
              39.90014436,
              21.392838516
            ],
            [
              39.900186055,
              21.392867314
            ],
            [
              39.900231574,
              21.392890486
            ],
            [
              39.90094348,
              21.393275813
            ],
            [
              39.900634496,
              21.394091607
            ],
            [
              39.900625691,
              21.39411517
            ],
            [
              39.900621004,
              21.394139745
            ],
            [
              39.900620555,
              21.394164706
            ],
            [
              39.900624353,
              21.394189416
            ],
            [
              39.900632303,
              21.394213244
            ],
            [
              39.900644202,
              21.394235583
            ],
            [
              39.900659748,
              21.394255862
            ],
            [
              39.900678542,
              21.394273566
            ],
            [
              39.90070561,
              21.394294539
            ],
            [
              39.900735411,
              21.394311963
            ],
            [
              39.900767417,
              21.394325528
            ],
            [
              39.900892076,
              21.394375027
            ],
            [
              39.900905802,
              21.394380468
            ],
            [
              39.90091848,
              21.394387807
            ],
            [
              39.900929815,
              21.394396872
            ],
            [
              39.900939539,
              21.394407451
            ],
            [
              39.900947425,
              21.394419295
            ],
            [
              39.90095329,
              21.394432129
            ],
            [
              39.900956994,
              21.39444565
            ],
            [
              39.900958452,
              21.394459541
            ],
            [
              39.900958105,
              21.394478806
            ],
            [
              39.900954794,
              21.394497822
            ],
            [
              39.900801752,
              21.394847398
            ],
            [
              39.900602786,
              21.395283551
            ],
            [
              39.900857625,
              21.395385446
            ],
            [
              39.901608734,
              21.395751436
            ],
            [
              39.901548732,
              21.395885927
            ],
            [
              39.901607653,
              21.395916226
            ],
            [
              39.90187026,
              21.395435942
            ],
            [
              39.902071953,
              21.395066519
            ],
            [
              39.902125652,
              21.395093663
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "15/716",
      "name": "5-5"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "b072eb4f-0dd4-4a07-9afe-c9cba557c2e2",
      "number": 10105,
      "total_pilgrims": 503,
      "name": "شركة مخيم الرشاد"
    },
    "schedule": [
      {
        "batch": {
          "id": "746c174b-8b74-4460-9410-0f5e9ee5e01c",
          "code": "INT1010501571603",
          "pilgrim": 127
        },
        "dispatching_day": 12,
        "dispatching_time": "17:40:00",
        "stoning_day": 12,
        "stoning_time": "18:00:00"
      },
      {
        "batch": {
          "id": "a82cfcf5-1937-42a9-87eb-a6dac604f1be",
          "code": "INT1010501571602",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "08:45:00",
        "stoning_day": 12,
        "stoning_time": "09:05:00"
      },
      {
        "batch": {
          "id": "746c174b-8b74-4460-9410-0f5e9ee5e01c",
          "code": "INT1010501571603",
          "pilgrim": 127
        },
        "dispatching_day": 10,
        "dispatching_time": "18:05:00",
        "stoning_day": 10,
        "stoning_time": "18:25:00"
      },
      {
        "batch": {
          "id": "a82cfcf5-1937-42a9-87eb-a6dac604f1be",
          "code": "INT1010501571602",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "09:30:00",
        "stoning_day": 10,
        "stoning_time": "09:50:00"
      },
      {
        "batch": {
          "id": "f12fb6f0-a14d-48f8-8ca2-213e1a54a65d",
          "code": "INT1010501571601",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "18:20:00",
        "stoning_day": 10,
        "stoning_time": "18:40:00"
      },
      {
        "batch": {
          "id": "746c174b-8b74-4460-9410-0f5e9ee5e01c",
          "code": "INT1010501571603",
          "pilgrim": 127
        },
        "dispatching_day": 11,
        "dispatching_time": "08:40:00",
        "stoning_day": 11,
        "stoning_time": "09:00:00"
      },
      {
        "batch": {
          "id": "a82cfcf5-1937-42a9-87eb-a6dac604f1be",
          "code": "INT1010501571602",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "13:40:00",
        "stoning_day": 13,
        "stoning_time": "14:00:00"
      },
      {
        "batch": {
          "id": "a82cfcf5-1937-42a9-87eb-a6dac604f1be",
          "code": "INT1010501571602",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "12:50:00",
        "stoning_day": 11,
        "stoning_time": "13:10:00"
      },
      {
        "batch": {
          "id": "f12fb6f0-a14d-48f8-8ca2-213e1a54a65d",
          "code": "INT1010501571601",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "11:00:00",
        "stoning_day": 11,
        "stoning_time": "11:20:00"
      },
      {
        "batch": {
          "id": "f12fb6f0-a14d-48f8-8ca2-213e1a54a65d",
          "code": "INT1010501571601",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "07:50:00",
        "stoning_day": 12,
        "stoning_time": "08:10:00"
      }
    ]
  },
  {
    "label": "1/126",
    "jamarat_level": {
      "id": 4,
      "name": "منشأة الجمرات - الطابق الثالث"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.873133612,
                21.425857417
              ],
              [
                39.873506661,
                21.425842122
              ],
              [
                39.873492507,
                21.425442447
              ],
              [
                39.872844213,
                21.425469917
              ],
              [
                39.872845875,
                21.425374183
              ],
              [
                39.872573492,
                21.425000254
              ],
              [
                39.872446465,
                21.424985663
              ],
              [
                39.872201059,
                21.425130961
              ],
              [
                39.87251612,
                21.425579898
              ],
              [
                39.872595055,
                21.425652818
              ],
              [
                39.87275568,
                21.425749264
              ],
              [
                39.872868403,
                21.425803436
              ],
              [
                39.872924216,
                21.425826771
              ],
              [
                39.872978549,
                21.425843802
              ],
              [
                39.873046844,
                21.425857052
              ],
              [
                39.873133612,
                21.425857417
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.873439764,
              21.425661108
            ],
            [
              39.873467352,
              21.425621956
            ],
            [
              39.873500836,
              21.425587067
            ],
            [
              39.873539474,
              21.425557212
            ],
            [
              39.873582412,
              21.42553305
            ],
            [
              39.873663955,
              21.425507765
            ],
            [
              39.873713531,
              21.425494055
            ],
            [
              39.873761112,
              21.425475141
            ],
            [
              39.87380608,
              21.425451269
            ],
            [
              39.873847847,
              21.42542275
            ],
            [
              39.873885867,
              21.425389958
            ],
            [
              39.874370632,
              21.424827128
            ],
            [
              39.874942377,
              21.424124244
            ],
            [
              39.875471777,
              21.42351337
            ],
            [
              39.87548185,
              21.423500202
            ],
            [
              39.875494181,
              21.423488841
            ],
            [
              39.875508405,
              21.423479623
            ],
            [
              39.875524104,
              21.423472819
            ],
            [
              39.875540815,
              21.42346863
            ],
            [
              39.875558045,
              21.42346718
            ],
            [
              39.875575286,
              21.423468511
            ],
            [
              39.875592029,
              21.423472584
            ],
            [
              39.875619765,
              21.423477856
            ],
            [
              39.875648045,
              21.42347886
            ],
            [
              39.875676128,
              21.423475572
            ],
            [
              39.875703273,
              21.423468076
            ],
            [
              39.875728768,
              21.423456571
            ],
            [
              39.875908435,
              21.423233328
            ],
            [
              39.875955278,
              21.423167645
            ],
            [
              39.875995631,
              21.423098288
            ],
            [
              39.876029171,
              21.423025819
            ],
            [
              39.876055624,
              21.422950822
            ],
            [
              39.876074779,
              21.422873904
            ],
            [
              39.876164869,
              21.422141982
            ],
            [
              39.87625141,
              21.421802125
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.871568415,
              21.4234645
            ],
            [
              39.871823746,
              21.42338912
            ],
            [
              39.871893537,
              21.423368516
            ],
            [
              39.871967855,
              21.423346576
            ],
            [
              39.871992527,
              21.423339292
            ],
            [
              39.8720498,
              21.423311719
            ],
            [
              39.872169124,
              21.42326105
            ],
            [
              39.872254056,
              21.423225838
            ],
            [
              39.872292648,
              21.423212705
            ],
            [
              39.872333002,
              21.423205603
            ],
            [
              39.872374053,
              21.423204717
            ],
            [
              39.872414714,
              21.423210073
            ],
            [
              39.872453911,
              21.423221529
            ],
            [
              39.872490745,
              21.423236867
            ],
            [
              39.872525254,
              21.423256377
            ],
            [
              39.872556903,
              21.423279756
            ],
            [
              39.8725852,
              21.423306639
            ],
            [
              39.872609704,
              21.423336611
            ],
            [
              39.873055864,
              21.423870474
            ],
            [
              39.873383264,
              21.424191466
            ],
            [
              39.873434414,
              21.424229772
            ],
            [
              39.873489556,
              21.424262869
            ],
            [
              39.873548085,
              21.424290393
            ],
            [
              39.873609365,
              21.424312046
            ],
            [
              39.873672724,
              21.424327591
            ],
            [
              39.87373747,
              21.424336857
            ],
            [
              39.873802895,
              21.424339743
            ],
            [
              39.873868285,
              21.424336217
            ],
            [
              39.8744691,
              21.42430402
            ],
            [
              39.874494222,
              21.424310363
            ],
            [
              39.874517891,
              21.424320483
            ],
            [
              39.874539462,
              21.424334104
            ],
            [
              39.874558354,
              21.424350859
            ],
            [
              39.874574049,
              21.424370289
            ],
            [
              39.874586125,
              21.424391869
            ],
            [
              39.874594252,
              21.424415013
            ],
            [
              39.874598209,
              21.424439093
            ],
            [
              39.87459789,
              21.424463454
            ],
            [
              39.874593302,
              21.424487436
            ],
            [
              39.874584572,
              21.424510385
            ],
            [
              39.874571934,
              21.424531682
            ],
            [
              39.874531254,
              21.424591839
            ],
            [
              39.873849827,
              21.42537525
            ],
            [
              39.873817349,
              21.425401465
            ],
            [
              39.873781554,
              21.425423572
            ],
            [
              39.873743033,
              21.425441209
            ],
            [
              39.873702418,
              21.425454085
            ],
            [
              39.873660377,
              21.425461988
            ],
            [
              39.873490142,
              21.425476493
            ],
            [
              39.873465746,
              21.425478105
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "1/126",
      "name": "26A"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "71f82395-4cb7-4416-ae74-4d658e2b134a",
      "number": 10128,
      "total_pilgrims": 1087,
      "name": "شركة مخيم الوفاء الحديثة"
    },
    "schedule": [
      {
        "batch": {
          "id": "57f61b6e-e24e-43d8-ac67-4ad3b5a3c4ed",
          "code": "INT1012800112603",
          "pilgrim": 188
        },
        "dispatching_day": 9,
        "dispatching_time": "22:40:00",
        "stoning_day": 9,
        "stoning_time": "23:00:00"
      },
      {
        "batch": {
          "id": "32039154-0d03-4873-b40a-a3632a05fe50",
          "code": "INT1012800112606",
          "pilgrim": 147
        },
        "dispatching_day": 10,
        "dispatching_time": "14:40:00",
        "stoning_day": 10,
        "stoning_time": "15:00:00"
      },
      {
        "batch": {
          "id": "0dcda310-2cba-4903-893b-4fcb77fae873",
          "code": "INT1012800112605",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "16:40:00",
        "stoning_day": 10,
        "stoning_time": "17:00:00"
      },
      {
        "batch": {
          "id": "1582fa33-9a33-4c56-9013-a1eaa31f109d",
          "code": "INT1012800112604",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:45:00",
        "stoning_day": 10,
        "stoning_time": "11:05:00"
      },
      {
        "batch": {
          "id": "e98ea19c-6683-4398-a624-e8f80e208a76",
          "code": "INT1012800112602",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "16:45:00",
        "stoning_day": 10,
        "stoning_time": "17:05:00"
      },
      {
        "batch": {
          "id": "b8a89bd6-6dc5-41e5-b924-35d1e2e92008",
          "code": "INT1012800112601",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:50:00",
        "stoning_day": 10,
        "stoning_time": "11:10:00"
      },
      {
        "batch": {
          "id": "32039154-0d03-4873-b40a-a3632a05fe50",
          "code": "INT1012800112606",
          "pilgrim": 147
        },
        "dispatching_day": 11,
        "dispatching_time": "07:40:00",
        "stoning_day": 11,
        "stoning_time": "08:00:00"
      },
      {
        "batch": {
          "id": "0dcda310-2cba-4903-893b-4fcb77fae873",
          "code": "INT1012800112605",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "16:40:00",
        "stoning_day": 11,
        "stoning_time": "17:00:00"
      },
      {
        "batch": {
          "id": "1582fa33-9a33-4c56-9013-a1eaa31f109d",
          "code": "INT1012800112604",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "05:45:00",
        "stoning_day": 11,
        "stoning_time": "06:05:00"
      },
      {
        "batch": {
          "id": "57f61b6e-e24e-43d8-ac67-4ad3b5a3c4ed",
          "code": "INT1012800112603",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "13:45:00",
        "stoning_day": 11,
        "stoning_time": "14:05:00"
      },
      {
        "batch": {
          "id": "e98ea19c-6683-4398-a624-e8f80e208a76",
          "code": "INT1012800112602",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "17:45:00",
        "stoning_day": 11,
        "stoning_time": "18:05:00"
      },
      {
        "batch": {
          "id": "b8a89bd6-6dc5-41e5-b924-35d1e2e92008",
          "code": "INT1012800112601",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "20:45:00",
        "stoning_day": 11,
        "stoning_time": "21:05:00"
      },
      {
        "batch": {
          "id": "0dcda310-2cba-4903-893b-4fcb77fae873",
          "code": "INT1012800112605",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "22:40:00",
        "stoning_day": 12,
        "stoning_time": "23:00:00"
      },
      {
        "batch": {
          "id": "32039154-0d03-4873-b40a-a3632a05fe50",
          "code": "INT1012800112606",
          "pilgrim": 147
        },
        "dispatching_day": 12,
        "dispatching_time": "09:45:00",
        "stoning_day": 12,
        "stoning_time": "10:05:00"
      },
      {
        "batch": {
          "id": "1582fa33-9a33-4c56-9013-a1eaa31f109d",
          "code": "INT1012800112604",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:45:00",
        "stoning_day": 12,
        "stoning_time": "16:05:00"
      },
      {
        "batch": {
          "id": "57f61b6e-e24e-43d8-ac67-4ad3b5a3c4ed",
          "code": "INT1012800112603",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "17:45:00",
        "stoning_day": 12,
        "stoning_time": "18:05:00"
      },
      {
        "batch": {
          "id": "b8a89bd6-6dc5-41e5-b924-35d1e2e92008",
          "code": "INT1012800112601",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "18:45:00",
        "stoning_day": 12,
        "stoning_time": "19:05:00"
      },
      {
        "batch": {
          "id": "e98ea19c-6683-4398-a624-e8f80e208a76",
          "code": "INT1012800112602",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "05:50:00",
        "stoning_day": 12,
        "stoning_time": "06:10:00"
      },
      {
        "batch": {
          "id": "0dcda310-2cba-4903-893b-4fcb77fae873",
          "code": "INT1012800112605",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "05:45:00",
        "stoning_day": 13,
        "stoning_time": "06:05:00"
      },
      {
        "batch": {
          "id": "57f61b6e-e24e-43d8-ac67-4ad3b5a3c4ed",
          "code": "INT1012800112603",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "06:45:00",
        "stoning_day": 13,
        "stoning_time": "07:05:00"
      },
      {
        "batch": {
          "id": "b8a89bd6-6dc5-41e5-b924-35d1e2e92008",
          "code": "INT1012800112601",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "07:45:00",
        "stoning_day": 13,
        "stoning_time": "08:05:00"
      }
    ]
  }
  ,
  {
    "label": "0/50",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.89591939,
                21.403437207
              ],
              [
                39.895918867,
                21.403436542
              ],
              [
                39.895893457,
                21.403409345
              ],
              [
                39.895877766,
                21.403422201
              ],
              [
                39.895856795,
                21.403404039
              ],
              [
                39.895845581,
                21.403416102
              ],
              [
                39.89580128,
                21.403378983
              ],
              [
                39.895812626,
                21.403366842
              ],
              [
                39.895813172,
                21.403366258
              ],
              [
                39.89579853,
                21.403353577
              ],
              [
                39.895782296,
                21.403370533
              ],
              [
                39.895638435,
                21.403521066
              ],
              [
                39.89549739,
                21.403664763
              ],
              [
                39.895440212,
                21.40372271
              ],
              [
                39.895436198,
                21.403726778
              ],
              [
                39.895434539,
                21.40372846
              ],
              [
                39.895381561,
                21.40378215
              ],
              [
                39.895373977,
                21.403789835
              ],
              [
                39.895308649,
                21.403856043
              ],
              [
                39.895294873,
                21.40386961
              ],
              [
                39.895203636,
                21.403959464
              ],
              [
                39.895229359,
                21.403982043
              ],
              [
                39.895329881,
                21.403903417
              ],
              [
                39.895415567,
                21.403836396
              ],
              [
                39.895484811,
                21.403780056
              ],
              [
                39.895537124,
                21.403737494
              ],
              [
                39.895617143,
                21.403674319
              ],
              [
                39.895666383,
                21.403637107
              ],
              [
                39.895751048,
                21.403571915
              ],
              [
                39.89591939,
                21.403437207
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.895701327,
              21.403475233
            ],
            [
              39.895657303,
              21.403445197
            ],
            [
              39.895614083,
              21.403416539
            ],
            [
              39.896239298,
              21.40276901
            ],
            [
              39.895758066,
              21.402308878
            ],
            [
              39.894780703,
              21.403081968
            ],
            [
              39.894573473,
              21.403277895
            ],
            [
              39.894008312,
              21.403810884
            ],
            [
              39.893355815,
              21.404382921
            ],
            [
              39.892927059,
              21.404760422
            ],
            [
              39.892857758,
              21.404718481
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.892041692,
              21.405468078
            ],
            [
              39.892096205,
              21.405514649
            ],
            [
              39.892212908,
              21.4053969
            ],
            [
              39.89281472,
              21.404879768
            ],
            [
              39.89342407,
              21.404343472
            ],
            [
              39.894096623,
              21.40496837
            ],
            [
              39.894131165,
              21.404937853
            ],
            [
              39.894148172,
              21.404922782
            ],
            [
              39.894153014,
              21.404918491
            ],
            [
              39.894242354,
              21.404839327
            ],
            [
              39.894387369,
              21.404710829
            ],
            [
              39.894424118,
              21.404678266
            ],
            [
              39.894575095,
              21.404544484
            ],
            [
              39.894600718,
              21.404518826
            ],
            [
              39.894672706,
              21.404446741
            ],
            [
              39.894776873,
              21.404342434
            ],
            [
              39.894876338,
              21.404242834
            ],
            [
              39.894976547,
              21.40414249
            ],
            [
              39.895072287,
              21.40404662
            ],
            [
              39.895213568,
              21.403905147
            ],
            [
              39.895262355,
              21.403856294
            ],
            [
              39.895320989,
              21.403797579
            ],
            [
              39.895402798,
              21.403711861
            ],
            [
              39.895459605,
              21.403652342
            ],
            [
              39.895657303,
              21.403445197
            ],
            [
              39.895701327,
              21.403475233
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "0/50",
      "name": "73C"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "13012bcf-09bd-4ccc-9f0c-2ffaa789a508",
      "number": 641,
      "total_pilgrims": 220,
      "name": "مؤسسة فهيد معيش القرشي"
    },
    "schedule": [
      {
        "batch": {
          "id": "670575ff-aa4a-48d2-a850-02412694b8f2",
          "code": "INT0064100005002",
          "pilgrim": 32
        },
        "dispatching_day": 12,
        "dispatching_time": "09:40:00",
        "stoning_day": 12,
        "stoning_time": "10:00:00"
      },
      {
        "batch": {
          "id": "68139d41-9b14-473e-bec4-c7076d923d58",
          "code": "INT0064100005001",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "09:40:00",
        "stoning_day": 12,
        "stoning_time": "10:00:00"
      },
      {
        "batch": {
          "id": "670575ff-aa4a-48d2-a850-02412694b8f2",
          "code": "INT0064100005002",
          "pilgrim": 32
        },
        "dispatching_day": 9,
        "dispatching_time": "22:15:00",
        "stoning_day": 9,
        "stoning_time": "22:35:00"
      },
      {
        "batch": {
          "id": "68139d41-9b14-473e-bec4-c7076d923d58",
          "code": "INT0064100005001",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "16:30:00",
        "stoning_day": 10,
        "stoning_time": "16:50:00"
      },
      {
        "batch": {
          "id": "670575ff-aa4a-48d2-a850-02412694b8f2",
          "code": "INT0064100005002",
          "pilgrim": 32
        },
        "dispatching_day": 11,
        "dispatching_time": "07:40:00",
        "stoning_day": 11,
        "stoning_time": "08:00:00"
      },
      {
        "batch": {
          "id": "68139d41-9b14-473e-bec4-c7076d923d58",
          "code": "INT0064100005001",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "07:40:00",
        "stoning_day": 11,
        "stoning_time": "08:00:00"
      },
      {
        "batch": {
          "id": "670575ff-aa4a-48d2-a850-02412694b8f2",
          "code": "INT0064100005002",
          "pilgrim": 32
        },
        "dispatching_day": 13,
        "dispatching_time": "06:40:00",
        "stoning_day": 13,
        "stoning_time": "07:00:00"
      }
    ]
  }
  ,
  {
    "label": "1/716",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.898050836,
                21.400985423
              ],
              [
                39.898050782,
                21.40098542
              ],
              [
                39.898048684,
                21.400985644
              ],
              [
                39.898048644,
                21.400985648
              ],
              [
                39.898046604,
                21.400986202
              ],
              [
                39.898046577,
                21.40098621
              ],
              [
                39.898044647,
                21.400987084
              ],
              [
                39.898042889,
                21.400988258
              ],
              [
                39.897892128,
                21.401101161
              ],
              [
                39.8978102,
                21.401162901
              ],
              [
                39.897631444,
                21.401297611
              ],
              [
                39.897385229,
                21.401481073
              ],
              [
                39.897314164,
                21.401534706
              ],
              [
                39.897150999,
                21.401657848
              ],
              [
                39.897243746,
                21.401763169
              ],
              [
                39.897248322,
                21.401768364
              ],
              [
                39.897256544,
                21.401761114
              ],
              [
                39.897481788,
                21.401545712
              ],
              [
                39.897603481,
                21.401433865
              ],
              [
                39.897686623,
                21.401357451
              ],
              [
                39.897918844,
                21.401139641
              ],
              [
                39.89804372,
                21.401021519
              ],
              [
                39.8980507,
                21.401014916
              ],
              [
                39.898060225,
                21.401005794
              ],
              [
                39.898061611,
                21.401004237
              ],
              [
                39.898061633,
                21.401004211
              ],
              [
                39.898062695,
                21.401002482
              ],
              [
                39.898062731,
                21.401002425
              ],
              [
                39.898063448,
                21.401000581
              ],
              [
                39.898063484,
                21.401000488
              ],
              [
                39.898063847,
                21.40099859
              ],
              [
                39.898063872,
                21.40099846
              ],
              [
                39.898063875,
                21.400997699
              ],
              [
                39.898063881,
                21.400996399
              ],
              [
                39.898063726,
                21.400995528
              ],
              [
                39.898063526,
                21.400994411
              ],
              [
                39.898062868,
                21.400992641
              ],
              [
                39.898062819,
                21.400992508
              ],
              [
                39.898061847,
                21.400990855
              ],
              [
                39.898061781,
                21.400990743
              ],
              [
                39.898060517,
                21.400989256
              ],
              [
                39.898060442,
                21.400989166
              ],
              [
                39.898058919,
                21.400987891
              ],
              [
                39.898058838,
                21.400987822
              ],
              [
                39.898057097,
                21.400986797
              ],
              [
                39.898057017,
                21.40098675
              ],
              [
                39.898055104,
                21.400986008
              ],
              [
                39.898055028,
                21.400985979
              ],
              [
                39.898052997,
                21.400985545
              ],
              [
                39.89805293,
                21.400985531
              ],
              [
                39.898050836,
                21.400985423
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.89731193,
              21.401554315
            ],
            [
              39.897282779,
              21.40151942
            ],
            [
              39.898351384,
              21.400683046
            ],
            [
              39.898061198,
              21.400377203
            ],
            [
              39.897562415,
              21.400831652
            ],
            [
              39.896985824,
              21.401288368
            ],
            [
              39.896916571,
              21.401224195
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.898111057,
              21.399978416
            ],
            [
              39.898331211,
              21.400083608
            ],
            [
              39.898114472,
              21.400328585
            ],
            [
              39.898402048,
              21.400610678
            ],
            [
              39.897682365,
              21.401183614
            ],
            [
              39.8972698,
              21.401504218
            ],
            [
              39.897282779,
              21.40151942
            ],
            [
              39.89731193,
              21.401554315
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "1/716",
      "name": "4-1"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "1f747982-14a6-457f-9eeb-a07aec6f22b8",
      "number": 667,
      "total_pilgrims": 259,
      "name": "مؤسسة سعود دهيران الشلوي"
    },
    "schedule": [
      {
        "batch": {
          "id": "e5dd923e-916c-4836-8213-ba68956ce518",
          "code": "INT0066700171602",
          "pilgrim": 71
        },
        "dispatching_day": 11,
        "dispatching_time": "21:40:00",
        "stoning_day": 11,
        "stoning_time": "22:00:00"
      },
      {
        "batch": {
          "id": "8f037858-c2d5-4ef2-b9a3-724256435b58",
          "code": "INT0066700171601",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "21:40:00",
        "stoning_day": 11,
        "stoning_time": "22:00:00"
      },
      {
        "batch": {
          "id": "e5dd923e-916c-4836-8213-ba68956ce518",
          "code": "INT0066700171602",
          "pilgrim": 71
        },
        "dispatching_day": 12,
        "dispatching_time": "07:40:00",
        "stoning_day": 12,
        "stoning_time": "08:00:00"
      },
      {
        "batch": {
          "id": "8f037858-c2d5-4ef2-b9a3-724256435b58",
          "code": "INT0066700171601",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "07:40:00",
        "stoning_day": 12,
        "stoning_time": "08:00:00"
      },
      {
        "batch": {
          "id": "e5dd923e-916c-4836-8213-ba68956ce518",
          "code": "INT0066700171602",
          "pilgrim": 71
        },
        "dispatching_day": 13,
        "dispatching_time": "06:00:00",
        "stoning_day": 13,
        "stoning_time": "06:20:00"
      },
      {
        "batch": {
          "id": "e5dd923e-916c-4836-8213-ba68956ce518",
          "code": "INT0066700171602",
          "pilgrim": 71
        },
        "dispatching_day": 10,
        "dispatching_time": "13:00:00",
        "stoning_day": 10,
        "stoning_time": "13:20:00"
      },
      {
        "batch": {
          "id": "8f037858-c2d5-4ef2-b9a3-724256435b58",
          "code": "INT0066700171601",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:00:00",
        "stoning_day": 10,
        "stoning_time": "13:20:00"
      }
    ]
  }
  ,
  {
    "label": "21/50",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.898847752,
                21.400661623
              ],
              [
                39.898808198,
                21.400624728
              ],
              [
                39.898801979,
                21.400630575
              ],
              [
                39.898779219,
                21.400609344
              ],
              [
                39.898785438,
                21.400603497
              ],
              [
                39.898779796,
                21.400598234
              ],
              [
                39.898662248,
                21.400707306
              ],
              [
                39.898372506,
                21.40096923
              ],
              [
                39.897374083,
                21.401908093
              ],
              [
                39.897513282,
                21.402021491
              ],
              [
                39.898479486,
                21.401073353
              ],
              [
                39.898868504,
                21.400691603
              ],
              [
                39.898847752,
                21.400661623
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.897723178,
              21.401605294
            ],
            [
              39.89768176,
              21.401566047
            ],
            [
              39.89848044,
              21.400819066
            ],
            [
              39.898061198,
              21.400377203
            ],
            [
              39.897562415,
              21.400831652
            ],
            [
              39.896985824,
              21.401288368
            ],
            [
              39.896916571,
              21.401224195
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.898111057,
              21.399978416
            ],
            [
              39.898331211,
              21.400083608
            ],
            [
              39.898114472,
              21.400328585
            ],
            [
              39.898438021,
              21.400645965
            ],
            [
              39.898351444,
              21.400789662
            ],
            [
              39.897618186,
              21.401511244
            ],
            [
              39.89768176,
              21.401566047
            ],
            [
              39.897723178,
              21.401605294
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "21/50",
      "name": "5-1"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "3921ff43-a382-4dbd-a042-ba7c0108408d",
      "number": 774,
      "total_pilgrims": 100,
      "name": "مؤسسة عبدالله حمد العراجه"
    },
    "schedule": [
      {
        "batch": {
          "id": "3fd035b1-5d6b-4a66-a03c-ec6fcf835d39",
          "code": "INT0077402105001",
          "pilgrim": 100
        },
        "dispatching_day": 10,
        "dispatching_time": "15:05:00",
        "stoning_day": 10,
        "stoning_time": "15:25:00"
      },
      {
        "batch": {
          "id": "3fd035b1-5d6b-4a66-a03c-ec6fcf835d39",
          "code": "INT0077402105001",
          "pilgrim": 100
        },
        "dispatching_day": 11,
        "dispatching_time": "06:00:00",
        "stoning_day": 11,
        "stoning_time": "06:20:00"
      },
      {
        "batch": {
          "id": "3fd035b1-5d6b-4a66-a03c-ec6fcf835d39",
          "code": "INT0077402105001",
          "pilgrim": 100
        },
        "dispatching_day": 12,
        "dispatching_time": "06:00:00",
        "stoning_day": 12,
        "stoning_time": "06:20:00"
      }
    ]
  }
  ,
  {
    "label": "36/56",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.895309647,
                21.405014686
              ],
              [
                39.895304233,
                21.405011033
              ],
              [
                39.895241611,
                21.40496879
              ],
              [
                39.895225392,
                21.404956939
              ],
              [
                39.895200761,
                21.40498896
              ],
              [
                39.895138138,
                21.404946717
              ],
              [
                39.895129094,
                21.404938657
              ],
              [
                39.895115653,
                21.404925694
              ],
              [
                39.89506189,
                21.404873841
              ],
              [
                39.895049026,
                21.404858896
              ],
              [
                39.894995263,
                21.404807044
              ],
              [
                39.8949415,
                21.404755191
              ],
              [
                39.894927521,
                21.404741709
              ],
              [
                39.89490064,
                21.404715783
              ],
              [
                39.894881277,
                21.404694649
              ],
              [
                39.894307119,
                21.405201995
              ],
              [
                39.8943125,
                21.405207299
              ],
              [
                39.894360279,
                21.40525439
              ],
              [
                39.894372747,
                21.405267267
              ],
              [
                39.894425903,
                21.405319665
              ],
              [
                39.894481857,
                21.405269886
              ],
              [
                39.894544804,
                21.405213885
              ],
              [
                39.894557979,
                21.40519856
              ],
              [
                39.894611028,
                21.405251053
              ],
              [
                39.894632736,
                21.405272006
              ],
              [
                39.894704815,
                21.405345384
              ],
              [
                39.894721472,
                21.40536168
              ],
              [
                39.894732089,
                21.405369619
              ],
              [
                39.894613193,
                21.405509079
              ],
              [
                39.894700181,
                21.405580613
              ],
              [
                39.894714547,
                21.405593381
              ],
              [
                39.89477254,
                21.405641071
              ],
              [
                39.894772843,
                21.40564132
              ],
              [
                39.894776538,
                21.405637352
              ],
              [
                39.894776757,
                21.405637116
              ],
              [
                39.894780257,
                21.405633356
              ],
              [
                39.894828647,
                21.405581372
              ],
              [
                39.895018026,
                21.405368455
              ],
              [
                39.895147662,
                21.405216095
              ],
              [
                39.895309647,
                21.405014686
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.894970466,
              21.405397159
            ],
            [
              39.895008927,
              21.405434536
            ],
            [
              39.894567562,
              21.405921817
            ],
            [
              39.89434636,
              21.406148646
            ],
            [
              39.893840901,
              21.405678291
            ],
            [
              39.893557632,
              21.405355571
            ],
            [
              39.893695077,
              21.40524919
            ],
            [
              39.893912728,
              21.405055756
            ],
            [
              39.894039617,
              21.404934366
            ],
            [
              39.893416524,
              21.404329223
            ],
            [
              39.892927059,
              21.404760422
            ],
            [
              39.892857758,
              21.404718481
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.892041692,
              21.405468078
            ],
            [
              39.892096205,
              21.405514649
            ],
            [
              39.892212908,
              21.4053969
            ],
            [
              39.89281472,
              21.404879768
            ],
            [
              39.89342407,
              21.404343472
            ],
            [
              39.894122721,
              21.403723235
            ],
            [
              39.894807275,
              21.403080592
            ],
            [
              39.895526174,
              21.402512629
            ],
            [
              39.895769968,
              21.402308146
            ],
            [
              39.896242291,
              21.402745447
            ],
            [
              39.896369912,
              21.402744765
            ],
            [
              39.896750206,
              21.403158653
            ],
            [
              39.896863271,
              21.403282347
            ],
            [
              39.895996874,
              21.404280415
            ],
            [
              39.895421432,
              21.405017807
            ],
            [
              39.895049352,
              21.405471109
            ],
            [
              39.895008927,
              21.405434536
            ],
            [
              39.894970466,
              21.405397159
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "36/56",
      "name": "74B"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "33a3f052-d90d-447b-b217-61104463698a",
      "number": 10003,
      "total_pilgrims": 1113,
      "name": "شركة المنار لخدمة حجاج الداخل والمعتمرين"
    },
    "schedule": [
      {
        "batch": {
          "id": "e3ef50a4-5af2-49c2-a7f8-cbbd4a962d3a",
          "code": "INT1000303605605",
          "pilgrim": 188
        },
        "dispatching_day": 9,
        "dispatching_time": "23:00:00",
        "stoning_day": 9,
        "stoning_time": "23:20:00"
      },
      {
        "batch": {
          "id": "cc4446f2-edca-4883-a67c-c8f33d25f275",
          "code": "INT1000303605602",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "20:15:00",
        "stoning_day": 11,
        "stoning_time": "20:35:00"
      },
      {
        "batch": {
          "id": "3b8ccdd3-7b0b-4528-ac3e-d827111f2891",
          "code": "INT1000303605601",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "18:40:00",
        "stoning_day": 11,
        "stoning_time": "19:00:00"
      },
      {
        "batch": {
          "id": "de10ca12-042e-4d8a-aa16-1d9376c86615",
          "code": "INT1000303605606",
          "pilgrim": 173
        },
        "dispatching_day": 12,
        "dispatching_time": "06:00:00",
        "stoning_day": 12,
        "stoning_time": "06:20:00"
      },
      {
        "batch": {
          "id": "e3ef50a4-5af2-49c2-a7f8-cbbd4a962d3a",
          "code": "INT1000303605605",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "22:00:00",
        "stoning_day": 12,
        "stoning_time": "22:20:00"
      },
      {
        "batch": {
          "id": "d094d34a-c293-4b08-af6e-c8ceb69d1c96",
          "code": "INT1000303605604",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "06:10:00",
        "stoning_day": 12,
        "stoning_time": "06:30:00"
      },
      {
        "batch": {
          "id": "ba6d9667-541c-40a4-8ab1-ceb3dfa02251",
          "code": "INT1000303605603",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "11:15:00",
        "stoning_day": 12,
        "stoning_time": "11:35:00"
      },
      {
        "batch": {
          "id": "cc4446f2-edca-4883-a67c-c8f33d25f275",
          "code": "INT1000303605602",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:40:00",
        "stoning_day": 12,
        "stoning_time": "16:00:00"
      },
      {
        "batch": {
          "id": "3b8ccdd3-7b0b-4528-ac3e-d827111f2891",
          "code": "INT1000303605601",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "16:55:00",
        "stoning_day": 12,
        "stoning_time": "17:15:00"
      },
      {
        "batch": {
          "id": "e3ef50a4-5af2-49c2-a7f8-cbbd4a962d3a",
          "code": "INT1000303605605",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "07:00:00",
        "stoning_day": 13,
        "stoning_time": "07:20:00"
      },
      {
        "batch": {
          "id": "ba6d9667-541c-40a4-8ab1-ceb3dfa02251",
          "code": "INT1000303605603",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "14:00:00",
        "stoning_day": 13,
        "stoning_time": "14:20:00"
      },
      {
        "batch": {
          "id": "3b8ccdd3-7b0b-4528-ac3e-d827111f2891",
          "code": "INT1000303605601",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "12:05:00",
        "stoning_day": 13,
        "stoning_time": "12:25:00"
      },
      {
        "batch": {
          "id": "de10ca12-042e-4d8a-aa16-1d9376c86615",
          "code": "INT1000303605606",
          "pilgrim": 173
        },
        "dispatching_day": 10,
        "dispatching_time": "20:45:00",
        "stoning_day": 10,
        "stoning_time": "21:05:00"
      },
      {
        "batch": {
          "id": "d094d34a-c293-4b08-af6e-c8ceb69d1c96",
          "code": "INT1000303605604",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:50:00",
        "stoning_day": 10,
        "stoning_time": "14:10:00"
      },
      {
        "batch": {
          "id": "ba6d9667-541c-40a4-8ab1-ceb3dfa02251",
          "code": "INT1000303605603",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "18:00:00",
        "stoning_day": 10,
        "stoning_time": "18:20:00"
      },
      {
        "batch": {
          "id": "cc4446f2-edca-4883-a67c-c8f33d25f275",
          "code": "INT1000303605602",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:10:00",
        "stoning_day": 10,
        "stoning_time": "10:30:00"
      },
      {
        "batch": {
          "id": "3b8ccdd3-7b0b-4528-ac3e-d827111f2891",
          "code": "INT1000303605601",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "16:25:00",
        "stoning_day": 10,
        "stoning_time": "16:45:00"
      },
      {
        "batch": {
          "id": "de10ca12-042e-4d8a-aa16-1d9376c86615",
          "code": "INT1000303605606",
          "pilgrim": 173
        },
        "dispatching_day": 11,
        "dispatching_time": "21:40:00",
        "stoning_day": 11,
        "stoning_time": "22:00:00"
      },
      {
        "batch": {
          "id": "e3ef50a4-5af2-49c2-a7f8-cbbd4a962d3a",
          "code": "INT1000303605605",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "13:55:00",
        "stoning_day": 11,
        "stoning_time": "14:15:00"
      },
      {
        "batch": {
          "id": "d094d34a-c293-4b08-af6e-c8ceb69d1c96",
          "code": "INT1000303605604",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "10:05:00",
        "stoning_day": 11,
        "stoning_time": "10:25:00"
      },
      {
        "batch": {
          "id": "ba6d9667-541c-40a4-8ab1-ceb3dfa02251",
          "code": "INT1000303605603",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "17:10:00",
        "stoning_day": 11,
        "stoning_time": "17:30:00"
      }
    ]
  }
  ,
  {
    "label": "5/714",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.901195634,
                21.39655157
              ],
              [
                39.900383494,
                21.396255652
              ],
              [
                39.900001523,
                21.39712116
              ],
              [
                39.900811424,
                21.397430809
              ],
              [
                39.901195634,
                21.39655157
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.900275222,
              21.39654565
            ],
            [
              39.90020089,
              21.396513017
            ],
            [
              39.900085602,
              21.396763708
            ],
            [
              39.899768398,
              21.397482517
            ],
            [
              39.899575679,
              21.397689122
            ],
            [
              39.899490733,
              21.397811884
            ],
            [
              39.899371567,
              21.397807176
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.898111057,
              21.399978416
            ],
            [
              39.898331211,
              21.400083608
            ],
            [
              39.898114472,
              21.400328585
            ],
            [
              39.898438021,
              21.400645965
            ],
            [
              39.899043584,
              21.400122816
            ],
            [
              39.899902247,
              21.399269145
            ],
            [
              39.900268408,
              21.398864155
            ],
            [
              39.900328743,
              21.398713239
            ],
            [
              39.899606594,
              21.397958483
            ],
            [
              39.899426133,
              21.397871581
            ],
            [
              39.899458018,
              21.397760869
            ],
            [
              39.899716512,
              21.397458297
            ],
            [
              39.900009202,
              21.396773574
            ],
            [
              39.900128361,
              21.396818812
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "5/714",
      "name": "5-4"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "719292b7-6e9d-48b4-b6dc-4e56b0166d37",
      "number": 10155,
      "total_pilgrims": 1410,
      "name": "شركة الفارس المحدودة"
    },
    "schedule": [
      {
        "batch": {
          "id": "7afe29ba-1faa-45b1-9843-2f4e37422c00",
          "code": "INT1015500571405",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "14:45:00",
        "stoning_day": 10,
        "stoning_time": "15:05:00"
      },
      {
        "batch": {
          "id": "8aab36bb-fd1e-4742-9198-126d00857482",
          "code": "INT1015500571404",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "19:45:00",
        "stoning_day": 10,
        "stoning_time": "20:05:00"
      },
      {
        "batch": {
          "id": "8b0d249a-a0f2-49ca-84f7-4ad4462c2308",
          "code": "INT1015500571403",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "09:15:00",
        "stoning_day": 10,
        "stoning_time": "09:35:00"
      },
      {
        "batch": {
          "id": "409fa830-00f9-43cc-83d8-a393dc67bb14",
          "code": "INT1015500571402",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "16:55:00",
        "stoning_day": 10,
        "stoning_time": "17:15:00"
      },
      {
        "batch": {
          "id": "14fea757-a3d4-4529-8c83-0b413f3f51ee",
          "code": "INT1015500571408",
          "pilgrim": 94
        },
        "dispatching_day": 11,
        "dispatching_time": "10:40:00",
        "stoning_day": 11,
        "stoning_time": "11:00:00"
      },
      {
        "batch": {
          "id": "50d96806-4868-44a6-a410-490f22745b29",
          "code": "INT1015500571407",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "06:45:00",
        "stoning_day": 11,
        "stoning_time": "07:05:00"
      },
      {
        "batch": {
          "id": "2e7ef230-6993-4140-96fe-01684cf31b23",
          "code": "INT1015500571406",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "20:45:00",
        "stoning_day": 11,
        "stoning_time": "21:05:00"
      },
      {
        "batch": {
          "id": "7afe29ba-1faa-45b1-9843-2f4e37422c00",
          "code": "INT1015500571405",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "17:50:00",
        "stoning_day": 11,
        "stoning_time": "18:10:00"
      },
      {
        "batch": {
          "id": "8aab36bb-fd1e-4742-9198-126d00857482",
          "code": "INT1015500571404",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "12:55:00",
        "stoning_day": 11,
        "stoning_time": "13:15:00"
      },
      {
        "batch": {
          "id": "8b0d249a-a0f2-49ca-84f7-4ad4462c2308",
          "code": "INT1015500571403",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "20:55:00",
        "stoning_day": 11,
        "stoning_time": "21:15:00"
      },
      {
        "batch": {
          "id": "409fa830-00f9-43cc-83d8-a393dc67bb14",
          "code": "INT1015500571402",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "11:00:00",
        "stoning_day": 11,
        "stoning_time": "11:20:00"
      },
      {
        "batch": {
          "id": "0fd505ce-41d0-4d78-8b26-de83319741a8",
          "code": "INT1015500571401",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "16:00:00",
        "stoning_day": 11,
        "stoning_time": "16:20:00"
      },
      {
        "batch": {
          "id": "14fea757-a3d4-4529-8c83-0b413f3f51ee",
          "code": "INT1015500571408",
          "pilgrim": 94
        },
        "dispatching_day": 12,
        "dispatching_time": "07:50:00",
        "stoning_day": 12,
        "stoning_time": "08:10:00"
      },
      {
        "batch": {
          "id": "50d96806-4868-44a6-a410-490f22745b29",
          "code": "INT1015500571407",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:10:00",
        "stoning_day": 12,
        "stoning_time": "15:30:00"
      },
      {
        "batch": {
          "id": "7afe29ba-1faa-45b1-9843-2f4e37422c00",
          "code": "INT1015500571405",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "19:50:00",
        "stoning_day": 12,
        "stoning_time": "20:10:00"
      },
      {
        "batch": {
          "id": "2e7ef230-6993-4140-96fe-01684cf31b23",
          "code": "INT1015500571406",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "10:55:00",
        "stoning_day": 12,
        "stoning_time": "11:15:00"
      },
      {
        "batch": {
          "id": "8b0d249a-a0f2-49ca-84f7-4ad4462c2308",
          "code": "INT1015500571403",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "19:55:00",
        "stoning_day": 12,
        "stoning_time": "20:15:00"
      },
      {
        "batch": {
          "id": "8aab36bb-fd1e-4742-9198-126d00857482",
          "code": "INT1015500571404",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "10:00:00",
        "stoning_day": 12,
        "stoning_time": "10:20:00"
      },
      {
        "batch": {
          "id": "409fa830-00f9-43cc-83d8-a393dc67bb14",
          "code": "INT1015500571402",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "18:00:00",
        "stoning_day": 12,
        "stoning_time": "18:20:00"
      },
      {
        "batch": {
          "id": "0fd505ce-41d0-4d78-8b26-de83319741a8",
          "code": "INT1015500571401",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "06:25:00",
        "stoning_day": 12,
        "stoning_time": "06:45:00"
      },
      {
        "batch": {
          "id": "50d96806-4868-44a6-a410-490f22745b29",
          "code": "INT1015500571407",
          "pilgrim": 188
        },
        "dispatching_day": 9,
        "dispatching_time": "22:20:00",
        "stoning_day": 9,
        "stoning_time": "22:40:00"
      },
      {
        "batch": {
          "id": "14fea757-a3d4-4529-8c83-0b413f3f51ee",
          "code": "INT1015500571408",
          "pilgrim": 94
        },
        "dispatching_day": 10,
        "dispatching_time": "16:40:00",
        "stoning_day": 10,
        "stoning_time": "17:00:00"
      },
      {
        "batch": {
          "id": "2e7ef230-6993-4140-96fe-01684cf31b23",
          "code": "INT1015500571406",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "17:40:00",
        "stoning_day": 10,
        "stoning_time": "18:00:00"
      },
      {
        "batch": {
          "id": "50d96806-4868-44a6-a410-490f22745b29",
          "code": "INT1015500571407",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "06:50:00",
        "stoning_day": 13,
        "stoning_time": "07:10:00"
      },
      {
        "batch": {
          "id": "7afe29ba-1faa-45b1-9843-2f4e37422c00",
          "code": "INT1015500571405",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "08:50:00",
        "stoning_day": 13,
        "stoning_time": "09:10:00"
      },
      {
        "batch": {
          "id": "8b0d249a-a0f2-49ca-84f7-4ad4462c2308",
          "code": "INT1015500571403",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "10:50:00",
        "stoning_day": 13,
        "stoning_time": "11:10:00"
      },
      {
        "batch": {
          "id": "0fd505ce-41d0-4d78-8b26-de83319741a8",
          "code": "INT1015500571401",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "12:50:00",
        "stoning_day": 13,
        "stoning_time": "13:10:00"
      },
      {
        "batch": {
          "id": "0fd505ce-41d0-4d78-8b26-de83319741a8",
          "code": "INT1015500571401",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:05:00",
        "stoning_day": 10,
        "stoning_time": "10:25:00"
      }
    ]
  }
  ,
  {
    "label": "62/44",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.90244754,
                21.392130679
              ],
              [
                39.902264932,
                21.392076905
              ],
              [
                39.902250517,
                21.392125897
              ],
              [
                39.902158399,
                21.39243431
              ],
              [
                39.902222482,
                21.392465289
              ],
              [
                39.902239786,
                21.392444455
              ],
              [
                39.902327948,
                21.392309325
              ],
              [
                39.90244754,
                21.392130679
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.902342811,
              21.392297811
            ],
            [
              39.902393484,
              21.392319463
            ],
            [
              39.903044098,
              21.391243288
            ],
            [
              39.903068056,
              21.391201334
            ],
            [
              39.903085129,
              21.391156523
            ],
            [
              39.903094941,
              21.391109841
            ],
            [
              39.903097277,
              21.391062313
            ],
            [
              39.903092085,
              21.391014984
            ],
            [
              39.903079479,
              21.390968895
            ],
            [
              39.902268652,
              21.389909971
            ],
            [
              39.901865949,
              21.389792619
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.901850034,
              21.389835559
            ],
            [
              39.902238611,
              21.389938645
            ],
            [
              39.903051691,
              21.391007539
            ],
            [
              39.90306075,
              21.39104421
            ],
            [
              39.903063598,
              21.391081755
            ],
            [
              39.903060166,
              21.391119256
            ],
            [
              39.903050538,
              21.3911558
            ],
            [
              39.903034948,
              21.391190492
            ],
            [
              39.902922548,
              21.391382325
            ],
            [
              39.90250449,
              21.392090926
            ],
            [
              39.902372541,
              21.392310514
            ],
            [
              39.902342811,
              21.392297811
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "62/44",
      "name": "4-4"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "24003615-35c4-4060-a017-f9747e444b4c",
      "number": 10042,
      "total_pilgrims": 1128,
      "name": "شركة جوهرة القوافل للحج والعمرة المحدودة"
    },
    "schedule": [
      {
        "batch": {
          "id": "efa94175-45d9-471e-ac0f-15daa33a4eee",
          "code": "INT1004206204406",
          "pilgrim": 188
        },
        "dispatching_day": 9,
        "dispatching_time": "22:05:00",
        "stoning_day": 9,
        "stoning_time": "22:25:00"
      },
      {
        "batch": {
          "id": "329c96ba-0cb1-4ebc-af58-9b1e5a52a4df",
          "code": "INT1004206204405",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:50:00",
        "stoning_day": 10,
        "stoning_time": "14:10:00"
      },
      {
        "batch": {
          "id": "dc1d88fc-2fd0-495e-ae32-cf72991f553d",
          "code": "INT1004206204404",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "18:50:00",
        "stoning_day": 10,
        "stoning_time": "19:10:00"
      },
      {
        "batch": {
          "id": "e44b5816-ad8b-4b73-8984-37817b0dc49a",
          "code": "INT1004206204403",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "12:05:00",
        "stoning_day": 10,
        "stoning_time": "12:25:00"
      },
      {
        "batch": {
          "id": "efa94175-45d9-471e-ac0f-15daa33a4eee",
          "code": "INT1004206204406",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "10:55:00",
        "stoning_day": 12,
        "stoning_time": "11:15:00"
      },
      {
        "batch": {
          "id": "51704dd4-5603-4a9d-932a-31585301394b",
          "code": "INT1004206204402",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "15:10:00",
        "stoning_day": 10,
        "stoning_time": "15:30:00"
      },
      {
        "batch": {
          "id": "329c96ba-0cb1-4ebc-af58-9b1e5a52a4df",
          "code": "INT1004206204405",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "07:00:00",
        "stoning_day": 12,
        "stoning_time": "07:20:00"
      },
      {
        "batch": {
          "id": "dc1d88fc-2fd0-495e-ae32-cf72991f553d",
          "code": "INT1004206204404",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "09:05:00",
        "stoning_day": 12,
        "stoning_time": "09:25:00"
      },
      {
        "batch": {
          "id": "e44b5816-ad8b-4b73-8984-37817b0dc49a",
          "code": "INT1004206204403",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:30:00",
        "stoning_day": 12,
        "stoning_time": "15:50:00"
      },
      {
        "batch": {
          "id": "51704dd4-5603-4a9d-932a-31585301394b",
          "code": "INT1004206204402",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "18:15:00",
        "stoning_day": 12,
        "stoning_time": "18:35:00"
      },
      {
        "batch": {
          "id": "7c7529da-42e5-4d81-bd59-1231c9538e95",
          "code": "INT1004206204401",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "12:30:00",
        "stoning_day": 10,
        "stoning_time": "12:50:00"
      },
      {
        "batch": {
          "id": "efa94175-45d9-471e-ac0f-15daa33a4eee",
          "code": "INT1004206204406",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "14:40:00",
        "stoning_day": 11,
        "stoning_time": "15:00:00"
      },
      {
        "batch": {
          "id": "7c7529da-42e5-4d81-bd59-1231c9538e95",
          "code": "INT1004206204401",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "07:25:00",
        "stoning_day": 12,
        "stoning_time": "07:45:00"
      },
      {
        "batch": {
          "id": "329c96ba-0cb1-4ebc-af58-9b1e5a52a4df",
          "code": "INT1004206204405",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "06:15:00",
        "stoning_day": 11,
        "stoning_time": "06:35:00"
      },
      {
        "batch": {
          "id": "dc1d88fc-2fd0-495e-ae32-cf72991f553d",
          "code": "INT1004206204404",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "21:00:00",
        "stoning_day": 11,
        "stoning_time": "21:20:00"
      },
      {
        "batch": {
          "id": "efa94175-45d9-471e-ac0f-15daa33a4eee",
          "code": "INT1004206204406",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "09:50:00",
        "stoning_day": 13,
        "stoning_time": "10:10:00"
      },
      {
        "batch": {
          "id": "dc1d88fc-2fd0-495e-ae32-cf72991f553d",
          "code": "INT1004206204404",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "06:55:00",
        "stoning_day": 13,
        "stoning_time": "07:15:00"
      },
      {
        "batch": {
          "id": "51704dd4-5603-4a9d-932a-31585301394b",
          "code": "INT1004206204402",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "12:55:00",
        "stoning_day": 13,
        "stoning_time": "13:15:00"
      },
      {
        "batch": {
          "id": "e44b5816-ad8b-4b73-8984-37817b0dc49a",
          "code": "INT1004206204403",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "09:10:00",
        "stoning_day": 11,
        "stoning_time": "09:30:00"
      },
      {
        "batch": {
          "id": "51704dd4-5603-4a9d-932a-31585301394b",
          "code": "INT1004206204402",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "11:15:00",
        "stoning_day": 11,
        "stoning_time": "11:35:00"
      },
      {
        "batch": {
          "id": "7c7529da-42e5-4d81-bd59-1231c9538e95",
          "code": "INT1004206204401",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "10:40:00",
        "stoning_day": 11,
        "stoning_time": "11:00:00"
      }
    ]
  }
  ,
  {
    "label": "1/502",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.898827656,
                21.401502839
              ],
              [
                39.898917917,
                21.40143292
              ],
              [
                39.898949459,
                21.401467949
              ],
              [
                39.898995708,
                21.401439355
              ],
              [
                39.899207799,
                21.401286005
              ],
              [
                39.89932845,
                21.401192855
              ],
              [
                39.899528039,
                21.401051774
              ],
              [
                39.8997537,
                21.400891825
              ],
              [
                39.899985043,
                21.400726941
              ],
              [
                39.900181309,
                21.40058766
              ],
              [
                39.900390846,
                21.400438499
              ],
              [
                39.900505025,
                21.400358633
              ],
              [
                39.900290824,
                21.400152379
              ],
              [
                39.900280982,
                21.400171633
              ],
              [
                39.899939152,
                21.400496166
              ],
              [
                39.899681,
                21.400730133
              ],
              [
                39.899429143,
                21.400950441
              ],
              [
                39.898998157,
                21.401302452
              ],
              [
                39.898963855,
                21.40133047
              ],
              [
                39.898796597,
                21.401470119
              ],
              [
                39.898827656,
                21.401502839
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.899939856,
              21.40074849
            ],
            [
              39.899981623,
              21.400783723
            ],
            [
              39.900583426,
              21.400355333
            ],
            [
              39.900380428,
              21.40013412
            ],
            [
              39.90008131,
              21.399638533
            ],
            [
              39.89995689,
              21.399336754
            ],
            [
              39.900185249,
              21.399051843
            ],
            [
              39.900225873,
              21.399001374
            ],
            [
              39.900262192,
              21.398948106
            ],
            [
              39.900293988,
              21.398892357
            ],
            [
              39.900321066,
              21.398834467
            ],
            [
              39.900343266,
              21.398774783
            ],
            [
              39.900353272,
              21.398755829
            ],
            [
              39.900359864,
              21.398735606
            ],
            [
              39.900362866,
              21.39871465
            ],
            [
              39.9003622,
              21.398693516
            ],
            [
              39.900357885,
              21.398672762
            ],
            [
              39.900350031,
              21.398652938
            ],
            [
              39.90033885,
              21.39863457
            ],
            [
              39.900324637,
              21.398618142
            ],
            [
              39.900307766,
              21.398604091
            ],
            [
              39.899490733,
              21.397811884
            ],
            [
              39.899371567,
              21.397807176
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.898111057,
              21.399978416
            ],
            [
              39.898331211,
              21.400083608
            ],
            [
              39.898114472,
              21.400328585
            ],
            [
              39.898438021,
              21.400645965
            ],
            [
              39.899043584,
              21.400122816
            ],
            [
              39.899902247,
              21.399269145
            ],
            [
              39.900048665,
              21.399652021
            ],
            [
              39.90033951,
              21.400130442
            ],
            [
              39.900594332,
              21.400394882
            ],
            [
              39.90000724,
              21.400802172
            ],
            [
              39.899981623,
              21.400783723
            ],
            [
              39.899939856,
              21.40074849
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "1/502",
      "name": "6-1"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "b38e84b6-dd85-47e0-abfc-3fb9826e2962",
      "number": 10048,
      "total_pilgrims": 617,
      "name": "شركة الاخلاص المتحدة"
    },
    "schedule": [
      {
        "batch": {
          "id": "3ed8cbc4-ea67-40da-8334-9deaf6a84b27",
          "code": "INT1004800150204",
          "pilgrim": 53
        },
        "dispatching_day": 10,
        "dispatching_time": "13:50:00",
        "stoning_day": 10,
        "stoning_time": "14:10:00"
      },
      {
        "batch": {
          "id": "25cae083-17a4-4494-8e37-c18c6593d931",
          "code": "INT1004800150203",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:50:00",
        "stoning_day": 10,
        "stoning_time": "14:10:00"
      },
      {
        "batch": {
          "id": "3ed8cbc4-ea67-40da-8334-9deaf6a84b27",
          "code": "INT1004800150204",
          "pilgrim": 53
        },
        "dispatching_day": 11,
        "dispatching_time": "19:40:00",
        "stoning_day": 11,
        "stoning_time": "20:00:00"
      },
      {
        "batch": {
          "id": "25cae083-17a4-4494-8e37-c18c6593d931",
          "code": "INT1004800150203",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "19:40:00",
        "stoning_day": 11,
        "stoning_time": "20:00:00"
      },
      {
        "batch": {
          "id": "d04be8c1-3fca-464f-aec7-fac4fd5dbde2",
          "code": "INT1004800150202",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "18:45:00",
        "stoning_day": 11,
        "stoning_time": "19:05:00"
      },
      {
        "batch": {
          "id": "1bed8a4a-f9bd-4528-b037-2f044721dadf",
          "code": "INT1004800150201",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "15:50:00",
        "stoning_day": 11,
        "stoning_time": "16:10:00"
      },
      {
        "batch": {
          "id": "3ed8cbc4-ea67-40da-8334-9deaf6a84b27",
          "code": "INT1004800150204",
          "pilgrim": 53
        },
        "dispatching_day": 12,
        "dispatching_time": "09:40:00",
        "stoning_day": 12,
        "stoning_time": "10:00:00"
      },
      {
        "batch": {
          "id": "25cae083-17a4-4494-8e37-c18c6593d931",
          "code": "INT1004800150203",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "09:40:00",
        "stoning_day": 12,
        "stoning_time": "10:00:00"
      },
      {
        "batch": {
          "id": "1bed8a4a-f9bd-4528-b037-2f044721dadf",
          "code": "INT1004800150201",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "18:40:00",
        "stoning_day": 12,
        "stoning_time": "19:00:00"
      },
      {
        "batch": {
          "id": "d04be8c1-3fca-464f-aec7-fac4fd5dbde2",
          "code": "INT1004800150202",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "08:45:00",
        "stoning_day": 12,
        "stoning_time": "09:05:00"
      },
      {
        "batch": {
          "id": "25cae083-17a4-4494-8e37-c18c6593d931",
          "code": "INT1004800150203",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "07:40:00",
        "stoning_day": 13,
        "stoning_time": "08:00:00"
      },
      {
        "batch": {
          "id": "1bed8a4a-f9bd-4528-b037-2f044721dadf",
          "code": "INT1004800150201",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "10:40:00",
        "stoning_day": 13,
        "stoning_time": "11:00:00"
      },
      {
        "batch": {
          "id": "d04be8c1-3fca-464f-aec7-fac4fd5dbde2",
          "code": "INT1004800150202",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "12:00:00",
        "stoning_day": 10,
        "stoning_time": "12:20:00"
      },
      {
        "batch": {
          "id": "1bed8a4a-f9bd-4528-b037-2f044721dadf",
          "code": "INT1004800150201",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "15:00:00",
        "stoning_day": 10,
        "stoning_time": "15:20:00"
      }
    ]
  }
  ,
  {
    "label": "15/44",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.893460727,
                21.40437757
              ],
              [
                39.892723185,
                21.405014877
              ],
              [
                39.892993312,
                21.405281828
              ],
              [
                39.893730855,
                21.404644522
              ],
              [
                39.893460727,
                21.40437757
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.892880209,
              21.404938139
            ],
            [
              39.892857758,
              21.404718481
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.892041692,
              21.405468078
            ],
            [
              39.892096205,
              21.405514649
            ],
            [
              39.892212908,
              21.4053969
            ],
            [
              39.89281472,
              21.404879768
            ],
            [
              39.892880209,
              21.404938139
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "15/44",
      "name": "72B"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "ed71b770-acd8-4b17-9436-0abc0116543a",
      "number": 10053,
      "total_pilgrims": 1270,
      "name": "شركة إفاضه"
    },
    "schedule": [
      {
        "batch": {
          "id": "9a9b98b9-788b-427f-a583-380927e8e15a",
          "code": "INT1005301504401",
          "pilgrim": 188
        },
        "dispatching_day": 9,
        "dispatching_time": "22:55:00",
        "stoning_day": 9,
        "stoning_time": "23:15:00"
      },
      {
        "batch": {
          "id": "3913312b-ae83-4010-bd04-3ad0f35b2fbc",
          "code": "INT1005301504403",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "15:15:00",
        "stoning_day": 11,
        "stoning_time": "15:35:00"
      },
      {
        "batch": {
          "id": "906302d8-7635-4570-84b5-a5db5bce4454",
          "code": "INT1005301504402",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "13:40:00",
        "stoning_day": 11,
        "stoning_time": "14:00:00"
      },
      {
        "batch": {
          "id": "9a9b98b9-788b-427f-a583-380927e8e15a",
          "code": "INT1005301504401",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "07:20:00",
        "stoning_day": 11,
        "stoning_time": "07:40:00"
      },
      {
        "batch": {
          "id": "6af98ce5-bb6f-48b8-b67b-72554ac2a6af",
          "code": "INT1005301504407",
          "pilgrim": 142
        },
        "dispatching_day": 12,
        "dispatching_time": "19:00:00",
        "stoning_day": 12,
        "stoning_time": "19:20:00"
      },
      {
        "batch": {
          "id": "fd42b539-48bc-4835-9d02-fd5980072513",
          "code": "INT1005301504405",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "20:05:00",
        "stoning_day": 12,
        "stoning_time": "20:25:00"
      },
      {
        "batch": {
          "id": "3913312b-ae83-4010-bd04-3ad0f35b2fbc",
          "code": "INT1005301504403",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "22:10:00",
        "stoning_day": 12,
        "stoning_time": "22:30:00"
      },
      {
        "batch": {
          "id": "c328d9a6-3c80-47de-af9d-754b623cc179",
          "code": "INT1005301504406",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "05:40:00",
        "stoning_day": 12,
        "stoning_time": "06:00:00"
      },
      {
        "batch": {
          "id": "906302d8-7635-4570-84b5-a5db5bce4454",
          "code": "INT1005301504402",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "18:25:00",
        "stoning_day": 12,
        "stoning_time": "18:45:00"
      },
      {
        "batch": {
          "id": "b9e480ba-ca0a-40bb-aa60-d32f58ce06eb",
          "code": "INT1005301504404",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "07:10:00",
        "stoning_day": 12,
        "stoning_time": "07:30:00"
      },
      {
        "batch": {
          "id": "9a9b98b9-788b-427f-a583-380927e8e15a",
          "code": "INT1005301504401",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "23:25:00",
        "stoning_day": 12,
        "stoning_time": "23:45:00"
      },
      {
        "batch": {
          "id": "6af98ce5-bb6f-48b8-b67b-72554ac2a6af",
          "code": "INT1005301504407",
          "pilgrim": 142
        },
        "dispatching_day": 10,
        "dispatching_time": "09:05:00",
        "stoning_day": 10,
        "stoning_time": "09:25:00"
      },
      {
        "batch": {
          "id": "c328d9a6-3c80-47de-af9d-754b623cc179",
          "code": "INT1005301504406",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:45:00",
        "stoning_day": 10,
        "stoning_time": "11:05:00"
      },
      {
        "batch": {
          "id": "6af98ce5-bb6f-48b8-b67b-72554ac2a6af",
          "code": "INT1005301504407",
          "pilgrim": 142
        },
        "dispatching_day": 13,
        "dispatching_time": "07:05:00",
        "stoning_day": 13,
        "stoning_time": "07:25:00"
      },
      {
        "batch": {
          "id": "fd42b539-48bc-4835-9d02-fd5980072513",
          "code": "INT1005301504405",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "13:05:00",
        "stoning_day": 13,
        "stoning_time": "13:25:00"
      },
      {
        "batch": {
          "id": "3913312b-ae83-4010-bd04-3ad0f35b2fbc",
          "code": "INT1005301504403",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "07:10:00",
        "stoning_day": 13,
        "stoning_time": "07:30:00"
      },
      {
        "batch": {
          "id": "9a9b98b9-788b-427f-a583-380927e8e15a",
          "code": "INT1005301504401",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "14:10:00",
        "stoning_day": 13,
        "stoning_time": "14:30:00"
      },
      {
        "batch": {
          "id": "fd42b539-48bc-4835-9d02-fd5980072513",
          "code": "INT1005301504405",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "09:15:00",
        "stoning_day": 10,
        "stoning_time": "09:35:00"
      },
      {
        "batch": {
          "id": "b9e480ba-ca0a-40bb-aa60-d32f58ce06eb",
          "code": "INT1005301504404",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "22:55:00",
        "stoning_day": 10,
        "stoning_time": "23:15:00"
      },
      {
        "batch": {
          "id": "3913312b-ae83-4010-bd04-3ad0f35b2fbc",
          "code": "INT1005301504403",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "20:10:00",
        "stoning_day": 10,
        "stoning_time": "20:30:00"
      },
      {
        "batch": {
          "id": "906302d8-7635-4570-84b5-a5db5bce4454",
          "code": "INT1005301504402",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "20:20:00",
        "stoning_day": 10,
        "stoning_time": "20:40:00"
      },
      {
        "batch": {
          "id": "6af98ce5-bb6f-48b8-b67b-72554ac2a6af",
          "code": "INT1005301504407",
          "pilgrim": 142
        },
        "dispatching_day": 11,
        "dispatching_time": "12:45:00",
        "stoning_day": 11,
        "stoning_time": "13:05:00"
      },
      {
        "batch": {
          "id": "c328d9a6-3c80-47de-af9d-754b623cc179",
          "code": "INT1005301504406",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "22:50:00",
        "stoning_day": 11,
        "stoning_time": "23:10:00"
      },
      {
        "batch": {
          "id": "fd42b539-48bc-4835-9d02-fd5980072513",
          "code": "INT1005301504405",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "20:00:00",
        "stoning_day": 11,
        "stoning_time": "20:20:00"
      },
      {
        "batch": {
          "id": "b9e480ba-ca0a-40bb-aa60-d32f58ce06eb",
          "code": "INT1005301504404",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "11:10:00",
        "stoning_day": 11,
        "stoning_time": "11:30:00"
      }
    ]
  }
  ,
  {
    "label": "32/50",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.886144404,
                21.410697778
              ],
              [
                39.885983553,
                21.410628817
              ],
              [
                39.885925736,
                21.41056876
              ],
              [
                39.885851343,
                21.410572705
              ],
              [
                39.885741811,
                21.410481505
              ],
              [
                39.886090752,
                21.410148879
              ],
              [
                39.885911106,
                21.409945756
              ],
              [
                39.88596919,
                21.409905235
              ],
              [
                39.885829708,
                21.409704413
              ],
              [
                39.885353859,
                21.410023537
              ],
              [
                39.885265201,
                21.410058103
              ],
              [
                39.884922584,
                21.410436773
              ],
              [
                39.885427666,
                21.411010782
              ],
              [
                39.885548853,
                21.411023779
              ],
              [
                39.885593239,
                21.410981806
              ],
              [
                39.885659794,
                21.411043246
              ],
              [
                39.885546404,
                21.411143953
              ],
              [
                39.885679348,
                21.411289629
              ],
              [
                39.886201836,
                21.410862023
              ],
              [
                39.88622938,
                21.410840183
              ],
              [
                39.886429038,
                21.410691388
              ],
              [
                39.886317823,
                21.410561156
              ],
              [
                39.886144404,
                21.410697778
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.885968604,
              21.411049089
            ],
            [
              39.886017615,
              21.411095163
            ],
            [
              39.886773218,
              21.410463697
            ],
            [
              39.887500169,
              21.409844332
            ],
            [
              39.887840348,
              21.409564382
            ],
            [
              39.887915499,
              21.409494839
            ],
            [
              39.888264391,
              21.409191913
            ],
            [
              39.88847321,
              21.409024329
            ],
            [
              39.888777469,
              21.40877636
            ],
            [
              39.889081654,
              21.408553869
            ],
            [
              39.889357702,
              21.408378633
            ],
            [
              39.889655888,
              21.40818934
            ],
            [
              39.890269792,
              21.407854869
            ],
            [
              39.889898756,
              21.407245609
            ],
            [
              39.890115127,
              21.407123011
            ],
            [
              39.890638849,
              21.406774823
            ],
            [
              39.890901859,
              21.406579806
            ],
            [
              39.891239578,
              21.406287582
            ],
            [
              39.891497642,
              21.40606844
            ],
            [
              39.891479903,
              21.40604936
            ],
            [
              39.891547825,
              21.40590151
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.891178383,
              21.404535649
            ],
            [
              39.891236745,
              21.404627617
            ],
            [
              39.890301381,
              21.405190302
            ],
            [
              39.888974308,
              21.406075801
            ],
            [
              39.888856072,
              21.406151725
            ],
            [
              39.888242335,
              21.406599585
            ],
            [
              39.887820881,
              21.406905394
            ],
            [
              39.88699531,
              21.407676592
            ],
            [
              39.887174155,
              21.407792893
            ],
            [
              39.886923963,
              21.408014608
            ],
            [
              39.887447952,
              21.408660219
            ],
            [
              39.887213665,
              21.408794029
            ],
            [
              39.886507286,
              21.409226239
            ],
            [
              39.885507035,
              21.40981826
            ],
            [
              39.885363959,
              21.409902678
            ],
            [
              39.885265426,
              21.409965946
            ],
            [
              39.884996232,
              21.410302018
            ],
            [
              39.884879094,
              21.410455022
            ],
            [
              39.88570551,
              21.411365448
            ],
            [
              39.88571983,
              21.411380861
            ],
            [
              39.886042328,
              21.411118001
            ],
            [
              39.885968604,
              21.411049089
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "32/50",
      "name": "54"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "7c5d2bcf-5de4-4952-ad7f-f86b7273c7d3",
      "number": 10182,
      "total_pilgrims": 590,
      "name": "شركة محمد عمر بالي فلمبان وشريكه"
    },
    "schedule": [
      {
        "batch": {
          "id": "81061982-3a44-4d8f-a4f4-79aedd518563",
          "code": "INT1018203205001",
          "pilgrim": 188
        },
        "dispatching_day": 9,
        "dispatching_time": "23:35:00",
        "stoning_day": 9,
        "stoning_time": "23:55:00"
      },
      {
        "batch": {
          "id": "4ceb3184-27d1-4ca5-83f9-4f53a84972d7",
          "code": "INT1018203205004",
          "pilgrim": 26
        },
        "dispatching_day": 12,
        "dispatching_time": "17:45:00",
        "stoning_day": 12,
        "stoning_time": "18:05:00"
      },
      {
        "batch": {
          "id": "c230b94d-ab12-41e3-97ae-4e22c6487af6",
          "code": "INT1018203205003",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "17:45:00",
        "stoning_day": 12,
        "stoning_time": "18:05:00"
      },
      {
        "batch": {
          "id": "aca0eabc-3884-4efd-b758-6758cfd11196",
          "code": "INT1018203205002",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:50:00",
        "stoning_day": 12,
        "stoning_time": "16:10:00"
      },
      {
        "batch": {
          "id": "81061982-3a44-4d8f-a4f4-79aedd518563",
          "code": "INT1018203205001",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:15:00",
        "stoning_day": 12,
        "stoning_time": "15:35:00"
      },
      {
        "batch": {
          "id": "4ceb3184-27d1-4ca5-83f9-4f53a84972d7",
          "code": "INT1018203205004",
          "pilgrim": 26
        },
        "dispatching_day": 10,
        "dispatching_time": "13:05:00",
        "stoning_day": 10,
        "stoning_time": "13:25:00"
      },
      {
        "batch": {
          "id": "c230b94d-ab12-41e3-97ae-4e22c6487af6",
          "code": "INT1018203205003",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:05:00",
        "stoning_day": 10,
        "stoning_time": "13:25:00"
      },
      {
        "batch": {
          "id": "aca0eabc-3884-4efd-b758-6758cfd11196",
          "code": "INT1018203205002",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "14:20:00",
        "stoning_day": 10,
        "stoning_time": "14:40:00"
      },
      {
        "batch": {
          "id": "4ceb3184-27d1-4ca5-83f9-4f53a84972d7",
          "code": "INT1018203205004",
          "pilgrim": 26
        },
        "dispatching_day": 11,
        "dispatching_time": "09:40:00",
        "stoning_day": 11,
        "stoning_time": "10:00:00"
      },
      {
        "batch": {
          "id": "c230b94d-ab12-41e3-97ae-4e22c6487af6",
          "code": "INT1018203205003",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "09:40:00",
        "stoning_day": 11,
        "stoning_time": "10:00:00"
      },
      {
        "batch": {
          "id": "aca0eabc-3884-4efd-b758-6758cfd11196",
          "code": "INT1018203205002",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "06:00:00",
        "stoning_day": 11,
        "stoning_time": "06:20:00"
      },
      {
        "batch": {
          "id": "81061982-3a44-4d8f-a4f4-79aedd518563",
          "code": "INT1018203205001",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "18:05:00",
        "stoning_day": 11,
        "stoning_time": "18:25:00"
      },
      {
        "batch": {
          "id": "c230b94d-ab12-41e3-97ae-4e22c6487af6",
          "code": "INT1018203205003",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "11:45:00",
        "stoning_day": 13,
        "stoning_time": "12:05:00"
      },
      {
        "batch": {
          "id": "81061982-3a44-4d8f-a4f4-79aedd518563",
          "code": "INT1018203205001",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "06:50:00",
        "stoning_day": 13,
        "stoning_time": "07:10:00"
      }
    ]
  }
  ,
  {
    "label": "34/56",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.894772843,
                21.40564132
              ],
              [
                39.89477254,
                21.405641071
              ],
              [
                39.894613193,
                21.405509079
              ],
              [
                39.894732089,
                21.405369619
              ],
              [
                39.894557979,
                21.40519856
              ],
              [
                39.894425903,
                21.405319665
              ],
              [
                39.894307119,
                21.405201995
              ],
              [
                39.893869386,
                21.405594772
              ],
              [
                39.894071005,
                21.4058015
              ],
              [
                39.894338016,
                21.406078128
              ],
              [
                39.894441412,
                21.405982139
              ],
              [
                39.894640415,
                21.405783584
              ],
              [
                39.894772843,
                21.40564132
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.894518461,
              21.405868701
            ],
            [
              39.894567562,
              21.405921817
            ],
            [
              39.89434636,
              21.406148646
            ],
            [
              39.893840901,
              21.405678291
            ],
            [
              39.893557632,
              21.405355571
            ],
            [
              39.893695077,
              21.40524919
            ],
            [
              39.893912728,
              21.405055756
            ],
            [
              39.894039617,
              21.404934366
            ],
            [
              39.893416524,
              21.404329223
            ],
            [
              39.892927059,
              21.404760422
            ],
            [
              39.892857758,
              21.404718481
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.892041692,
              21.405468078
            ],
            [
              39.892096205,
              21.405514649
            ],
            [
              39.892212908,
              21.4053969
            ],
            [
              39.89281472,
              21.404879768
            ],
            [
              39.89342407,
              21.404343472
            ],
            [
              39.894122721,
              21.403723235
            ],
            [
              39.894807275,
              21.403080592
            ],
            [
              39.895526174,
              21.402512629
            ],
            [
              39.895769968,
              21.402308146
            ],
            [
              39.896242291,
              21.402745447
            ],
            [
              39.896369912,
              21.402744765
            ],
            [
              39.896750206,
              21.403158653
            ],
            [
              39.896863271,
              21.403282347
            ],
            [
              39.895996874,
              21.404280415
            ],
            [
              39.895421432,
              21.405017807
            ],
            [
              39.895024141,
              21.405501825
            ],
            [
              39.894608233,
              21.405965588
            ],
            [
              39.894567562,
              21.405921817
            ],
            [
              39.894518461,
              21.405868701
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "34/56",
      "name": "74B"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "fe76725b-2e36-4c20-83bd-a7f7f7304c8c",
      "number": 10183,
      "total_pilgrims": 547,
      "name": "شركة مواسم الغفران المحدودة"
    },
    "schedule": [
      {
        "batch": {
          "id": "e3c6919c-6407-41cb-a787-6581ddffef45",
          "code": "INT1018303405603",
          "pilgrim": 171
        },
        "dispatching_day": 12,
        "dispatching_time": "09:45:00",
        "stoning_day": 12,
        "stoning_time": "10:05:00"
      },
      {
        "batch": {
          "id": "b9534930-2154-4d2a-bce6-6c4cd1481bd2",
          "code": "INT1018303405601",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "22:45:00",
        "stoning_day": 12,
        "stoning_time": "23:05:00"
      },
      {
        "batch": {
          "id": "0059abc1-7a12-48b3-86d9-5ac057f240b0",
          "code": "INT1018303405602",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "05:55:00",
        "stoning_day": 12,
        "stoning_time": "06:15:00"
      },
      {
        "batch": {
          "id": "e3c6919c-6407-41cb-a787-6581ddffef45",
          "code": "INT1018303405603",
          "pilgrim": 171
        },
        "dispatching_day": 10,
        "dispatching_time": "23:00:00",
        "stoning_day": 10,
        "stoning_time": "23:20:00"
      },
      {
        "batch": {
          "id": "0059abc1-7a12-48b3-86d9-5ac057f240b0",
          "code": "INT1018303405602",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "20:05:00",
        "stoning_day": 10,
        "stoning_time": "20:25:00"
      },
      {
        "batch": {
          "id": "b9534930-2154-4d2a-bce6-6c4cd1481bd2",
          "code": "INT1018303405601",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "21:25:00",
        "stoning_day": 10,
        "stoning_time": "21:45:00"
      },
      {
        "batch": {
          "id": "e3c6919c-6407-41cb-a787-6581ddffef45",
          "code": "INT1018303405603",
          "pilgrim": 171
        },
        "dispatching_day": 11,
        "dispatching_time": "16:40:00",
        "stoning_day": 11,
        "stoning_time": "17:00:00"
      },
      {
        "batch": {
          "id": "0059abc1-7a12-48b3-86d9-5ac057f240b0",
          "code": "INT1018303405602",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "11:55:00",
        "stoning_day": 11,
        "stoning_time": "12:15:00"
      },
      {
        "batch": {
          "id": "b9534930-2154-4d2a-bce6-6c4cd1481bd2",
          "code": "INT1018303405601",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "09:05:00",
        "stoning_day": 11,
        "stoning_time": "09:25:00"
      },
      {
        "batch": {
          "id": "e3c6919c-6407-41cb-a787-6581ddffef45",
          "code": "INT1018303405603",
          "pilgrim": 171
        },
        "dispatching_day": 13,
        "dispatching_time": "07:45:00",
        "stoning_day": 13,
        "stoning_time": "08:05:00"
      },
      {
        "batch": {
          "id": "b9534930-2154-4d2a-bce6-6c4cd1481bd2",
          "code": "INT1018303405601",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "13:45:00",
        "stoning_day": 13,
        "stoning_time": "14:05:00"
      }
    ]
  },
  {
    "label": "23/68",
    "jamarat_level": {
      "id": 4,
      "name": "منشأة الجمرات - الطابق الثالث"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.881408276,
                21.41953354
              ],
              [
                39.881411247,
                21.419588741
              ],
              [
                39.881682949,
                21.419514954
              ],
              [
                39.881692615,
                21.41942874
              ],
              [
                39.881312431,
                21.419421006
              ],
              [
                39.88123927,
                21.419420013
              ],
              [
                39.881167171,
                21.419417427
              ],
              [
                39.881079791,
                21.419481235
              ],
              [
                39.881082292,
                21.419528928
              ],
              [
                39.881095746,
                21.419595936
              ],
              [
                39.881103177,
                21.419637876
              ],
              [
                39.881136422,
                21.419660433
              ],
              [
                39.881315497,
                21.419601605
              ],
              [
                39.881310241,
                21.41952639
              ],
              [
                39.881408276,
                21.41953354
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.881260742,
              21.41944239
            ],
            [
              39.881167307,
              21.419372011
            ],
            [
              39.880719556,
              21.419449046
            ],
            [
              39.880521562,
              21.419483622
            ],
            [
              39.88032489,
              21.419524283
            ],
            [
              39.880129755,
              21.419570985
            ],
            [
              39.879936368,
              21.419623675
            ],
            [
              39.879744938,
              21.4196823
            ],
            [
              39.879555673,
              21.419746793
            ],
            [
              39.879368777,
              21.419817086
            ],
            [
              39.878655916,
              21.420138743
            ],
            [
              39.877599775,
              21.420686891
            ],
            [
              39.877006913,
              21.421012695
            ],
            [
              39.876808649,
              21.421330358
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.871568415,
              21.4234645
            ],
            [
              39.871627628,
              21.423447019
            ],
            [
              39.871671655,
              21.423434021
            ],
            [
              39.871823746,
              21.42338912
            ],
            [
              39.871893537,
              21.423368516
            ],
            [
              39.871967855,
              21.423346576
            ],
            [
              39.871992527,
              21.423339292
            ],
            [
              39.872049805,
              21.423311717
            ],
            [
              39.872067733,
              21.423303087
            ],
            [
              39.872203813,
              21.42317567
            ],
            [
              39.872655991,
              21.422752278
            ],
            [
              39.873208853,
              21.422425689
            ],
            [
              39.873731174,
              21.422117139
            ],
            [
              39.874549428,
              21.421633766
            ],
            [
              39.874977146,
              21.421409652
            ],
            [
              39.875340498,
              21.421219263
            ],
            [
              39.875842422,
              21.420956262
            ],
            [
              39.875910078,
              21.420920811
            ],
            [
              39.87609528,
              21.420823767
            ],
            [
              39.876114649,
              21.420813618
            ],
            [
              39.87615925,
              21.420790248
            ],
            [
              39.876312561,
              21.420709914
            ],
            [
              39.876442142,
              21.420642015
            ],
            [
              39.876466016,
              21.420629504
            ],
            [
              39.876524777,
              21.420598714
            ],
            [
              39.876692979,
              21.420520113
            ],
            [
              39.876701368,
              21.420516325
            ],
            [
              39.876729714,
              21.420503613
            ],
            [
              39.876758761,
              21.420490732
            ],
            [
              39.876765984,
              21.420487552
            ],
            [
              39.876767179,
              21.420487026
            ],
            [
              39.876770024,
              21.420485776
            ],
            [
              39.876771884,
              21.42048496
            ],
            [
              39.876775791,
              21.420483334
            ],
            [
              39.877292592,
              21.420363117
            ],
            [
              39.87731535,
              21.420356672
            ],
            [
              39.877338944,
              21.420353921
            ],
            [
              39.877362695,
              21.420354942
            ],
            [
              39.87738592,
              21.420359708
            ],
            [
              39.877407951,
              21.42036808
            ],
            [
              39.877428154,
              21.420379818
            ],
            [
              39.877445948,
              21.420394584
            ],
            [
              39.87758,
              21.420516905
            ],
            [
              39.878651471,
              21.419971068
            ],
            [
              39.878867778,
              21.419871095
            ],
            [
              39.879086836,
              21.419776518
            ],
            [
              39.879308491,
              21.419687404
            ],
            [
              39.879532587,
              21.419603816
            ],
            [
              39.879758967,
              21.419525811
            ],
            [
              39.879987473,
              21.419453445
            ],
            [
              39.880217945,
              21.419386769
            ],
            [
              39.880426222,
              21.419336286
            ],
            [
              39.880665848,
              21.419327864
            ],
            [
              39.881026861,
              21.419302094
            ],
            [
              39.881167307,
              21.419372011
            ],
            [
              39.881260742,
              21.41944239
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "23/68",
      "name": "28C"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "3d9b9c48-c712-4ff7-acf6-0614864b0fb4",
      "number": 10070,
      "total_pilgrims": 220,
      "name": "شركة بندر وبدر جميل القرشي وشركاهما"
    },
    "schedule": [
      {
        "batch": {
          "id": "a91d4838-4382-4918-ba14-bea5d6e1a8db",
          "code": "INT1007002306802",
          "pilgrim": 32
        },
        "dispatching_day": 12,
        "dispatching_time": "05:40:00",
        "stoning_day": 12,
        "stoning_time": "06:00:00"
      },
      {
        "batch": {
          "id": "0e22c139-55b5-41b0-9eb7-1463351cde5c",
          "code": "INT1007002306801",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "05:40:00",
        "stoning_day": 12,
        "stoning_time": "06:00:00"
      },
      {
        "batch": {
          "id": "0e22c139-55b5-41b0-9eb7-1463351cde5c",
          "code": "INT1007002306801",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "05:40:00",
        "stoning_day": 13,
        "stoning_time": "06:00:00"
      },
      {
        "batch": {
          "id": "a91d4838-4382-4918-ba14-bea5d6e1a8db",
          "code": "INT1007002306802",
          "pilgrim": 32
        },
        "dispatching_day": 10,
        "dispatching_time": "10:55:00",
        "stoning_day": 10,
        "stoning_time": "11:15:00"
      },
      {
        "batch": {
          "id": "0e22c139-55b5-41b0-9eb7-1463351cde5c",
          "code": "INT1007002306801",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:55:00",
        "stoning_day": 10,
        "stoning_time": "11:15:00"
      },
      {
        "batch": {
          "id": "a91d4838-4382-4918-ba14-bea5d6e1a8db",
          "code": "INT1007002306802",
          "pilgrim": 32
        },
        "dispatching_day": 11,
        "dispatching_time": "04:45:00",
        "stoning_day": 11,
        "stoning_time": "05:05:00"
      },
      {
        "batch": {
          "id": "0e22c139-55b5-41b0-9eb7-1463351cde5c",
          "code": "INT1007002306801",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "04:45:00",
        "stoning_day": 11,
        "stoning_time": "05:05:00"
      }
    ]
  }
  ,
  {
    "label": "56/44",
    "jamarat_level": {
      "id": 5,
      "name": "منشأة الجمرات - الطابق الرابع"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.901404319,
                21.392879241
              ],
              [
                39.901314206,
                21.392866201
              ],
              [
                39.901248997,
                21.393081438
              ],
              [
                39.901103805,
                21.393489512
              ],
              [
                39.901159478,
                21.393521118
              ],
              [
                39.90124468,
                21.393555081
              ],
              [
                39.90131836,
                21.39358337
              ],
              [
                39.901325256,
                21.39358405
              ],
              [
                39.901377754,
                21.393613797
              ],
              [
                39.901388231,
                21.393597921
              ],
              [
                39.901470624,
                21.393465052
              ],
              [
                39.901610743,
                21.393256337
              ],
              [
                39.901743405,
                21.393080172
              ],
              [
                39.901799123,
                21.393009596
              ],
              [
                39.901800969,
                21.393007213
              ],
              [
                39.901798051,
                21.393005169
              ],
              [
                39.901735427,
                21.392961308
              ],
              [
                39.901554341,
                21.392916172
              ],
              [
                39.90140874,
                21.39287988
              ],
              [
                39.901404319,
                21.392879241
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.901677133,
              21.393154692
            ],
            [
              39.901712901,
              21.3931796
            ],
            [
              39.901376389,
              21.393660675
            ],
            [
              39.901111091,
              21.393559316
            ],
            [
              39.901102861,
              21.393553565
            ],
            [
              39.901095719,
              21.393546655
            ],
            [
              39.90108985,
              21.393538763
            ],
            [
              39.901085407,
              21.393530094
            ],
            [
              39.901082502,
              21.39352087
            ],
            [
              39.901081212,
              21.393511329
            ],
            [
              39.901081567,
              21.393501718
            ],
            [
              39.901083562,
              21.393492285
            ],
            [
              39.901087143,
              21.393483272
            ],
            [
              39.90112622,
              21.393362588
            ],
            [
              39.901128982,
              21.393350114
            ],
            [
              39.901129476,
              21.393337382
            ],
            [
              39.901127687,
              21.393324754
            ],
            [
              39.901123668,
              21.393312583
            ],
            [
              39.90111753,
              21.393301214
            ],
            [
              39.901109447,
              21.393290967
            ],
            [
              39.901051131,
              21.393243853
            ],
            [
              39.900712275,
              21.393049394
            ],
            [
              39.90024065,
              21.392834932
            ],
            [
              39.900199523,
              21.392806376
            ],
            [
              39.900162763,
              21.392773005
            ],
            [
              39.900131006,
              21.392735397
            ],
            [
              39.900104804,
              21.392694204
            ],
            [
              39.900084613,
              21.392650143
            ],
            [
              39.900070783,
              21.392603978
            ],
            [
              39.90004127,
              21.392306418
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.89999929,
              21.392302749
            ],
            [
              39.900030179,
              21.392679303
            ],
            [
              39.900049641,
              21.392724269
            ],
            [
              39.900075502,
              21.392766311
            ],
            [
              39.900107276,
              21.392804636
            ],
            [
              39.90014436,
              21.392838516
            ],
            [
              39.900186055,
              21.392867314
            ],
            [
              39.900231574,
              21.392890486
            ],
            [
              39.90094348,
              21.393275813
            ],
            [
              39.900865441,
              21.393481856
            ],
            [
              39.901396552,
              21.39369034
            ],
            [
              39.901739614,
              21.393195126
            ],
            [
              39.901677133,
              21.393154692
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "56/44",
      "name": "4-4"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "e6e9e8b4-a35c-4337-81af-75fdd80abc56",
      "number": 10106,
      "total_pilgrims": 452,
      "name": "شركة حمد معيوف اللحياني وحمد حماد العتيبي"
    },
    "schedule": [
      {
        "batch": {
          "id": "1d40fd39-f5ea-46a5-9901-0b7a3d73aa5f",
          "code": "INT1010605604403",
          "pilgrim": 76
        },
        "dispatching_day": 9,
        "dispatching_time": "22:20:00",
        "stoning_day": 9,
        "stoning_time": "22:40:00"
      },
      {
        "batch": {
          "id": "2bd8247d-779e-421d-a148-d32c11edfff2",
          "code": "INT1010605604402",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "09:00:00",
        "stoning_day": 10,
        "stoning_time": "09:20:00"
      },
      {
        "batch": {
          "id": "6eccb0e3-1200-44ed-b6f2-ceae220cce98",
          "code": "INT1010605604401",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "15:20:00",
        "stoning_day": 10,
        "stoning_time": "15:40:00"
      },
      {
        "batch": {
          "id": "1d40fd39-f5ea-46a5-9901-0b7a3d73aa5f",
          "code": "INT1010605604403",
          "pilgrim": 76
        },
        "dispatching_day": 11,
        "dispatching_time": "18:45:00",
        "stoning_day": 11,
        "stoning_time": "19:05:00"
      },
      {
        "batch": {
          "id": "2bd8247d-779e-421d-a148-d32c11edfff2",
          "code": "INT1010605604402",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "18:45:00",
        "stoning_day": 11,
        "stoning_time": "19:05:00"
      },
      {
        "batch": {
          "id": "1d40fd39-f5ea-46a5-9901-0b7a3d73aa5f",
          "code": "INT1010605604403",
          "pilgrim": 76
        },
        "dispatching_day": 13,
        "dispatching_time": "10:40:00",
        "stoning_day": 13,
        "stoning_time": "11:00:00"
      },
      {
        "batch": {
          "id": "6eccb0e3-1200-44ed-b6f2-ceae220cce98",
          "code": "INT1010605604401",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "10:40:00",
        "stoning_day": 13,
        "stoning_time": "11:00:00"
      },
      {
        "batch": {
          "id": "6eccb0e3-1200-44ed-b6f2-ceae220cce98",
          "code": "INT1010605604401",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "18:55:00",
        "stoning_day": 11,
        "stoning_time": "19:15:00"
      },
      {
        "batch": {
          "id": "1d40fd39-f5ea-46a5-9901-0b7a3d73aa5f",
          "code": "INT1010605604403",
          "pilgrim": 76
        },
        "dispatching_day": 12,
        "dispatching_time": "15:00:00",
        "stoning_day": 12,
        "stoning_time": "15:20:00"
      },
      {
        "batch": {
          "id": "2bd8247d-779e-421d-a148-d32c11edfff2",
          "code": "INT1010605604402",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:00:00",
        "stoning_day": 12,
        "stoning_time": "15:20:00"
      },
      {
        "batch": {
          "id": "6eccb0e3-1200-44ed-b6f2-ceae220cce98",
          "code": "INT1010605604401",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "15:45:00",
        "stoning_day": 12,
        "stoning_time": "16:05:00"
      }
    ]
  }
  ,
  {
    "label": "2/108",
    "jamarat_level": {
      "id": 3,
      "name": "منشأة الجمرات - الطابق الثاني"
    },
    "shapes": {
      "actual_location": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                39.876065766,
                21.416194017
              ],
              [
                39.876058656,
                21.416192696
              ],
              [
                39.876161832,
                21.415882146
              ],
              [
                39.875885662,
                21.415767604
              ],
              [
                39.876204907,
                21.415296457
              ],
              [
                39.875914924,
                21.415125875
              ],
              [
                39.875529529,
                21.415718965
              ],
              [
                39.875461178,
                21.415875821
              ],
              [
                39.87549052,
                21.415937198
              ],
              [
                39.875393256,
                21.41597287
              ],
              [
                39.875404582,
                21.415997988
              ],
              [
                39.875315054,
                21.416033106
              ],
              [
                39.875536726,
                21.41640673
              ],
              [
                39.875536348,
                21.416738388
              ],
              [
                39.875919304,
                21.416998364
              ],
              [
                39.875988002,
                21.416836501
              ],
              [
                39.876066535,
                21.416445671
              ],
              [
                39.876039647,
                21.416318598
              ],
              [
                39.876047913,
                21.416272287
              ],
              [
                39.876065766,
                21.416194017
              ]
            ]
          ]
        ]
      },
      "access_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.876005103,
              21.416423454
            ],
            [
              39.876097625,
              21.416439801
            ],
            [
              39.87607405,
              21.416575359
            ],
            [
              39.876056795,
              21.416711748
            ],
            [
              39.876019047,
              21.416831789
            ],
            [
              39.875974337,
              21.416949715
            ],
            [
              39.875922799,
              21.417065172
            ],
            [
              39.875864587,
              21.417177814
            ],
            [
              39.875799875,
              21.417287302
            ],
            [
              39.875728858,
              21.417393308
            ],
            [
              39.87565175,
              21.417495513
            ],
            [
              39.874984125,
              21.417911338
            ],
            [
              39.874004211,
              21.418476475
            ]
          ]
        ]
      },
      "return_path": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              39.866483044,
              21.423769954
            ],
            [
              39.866380788,
              21.423769272
            ],
            [
              39.866278677,
              21.423764131
            ],
            [
              39.866176933,
              21.423754541
            ],
            [
              39.866075776,
              21.423740522
            ],
            [
              39.866027959,
              21.423732715
            ],
            [
              39.865981864,
              21.423718478
            ],
            [
              39.86593847,
              21.423698116
            ],
            [
              39.8658987,
              21.423672061
            ],
            [
              39.865863401,
              21.423640866
            ],
            [
              39.865833323,
              21.423605195
            ],
            [
              39.865809106,
              21.423565808
            ],
            [
              39.865791263,
              21.423523542
            ],
            [
              39.865780177,
              21.423479295
            ],
            [
              39.865776081,
              21.423434009
            ],
            [
              39.865779063,
              21.423388647
            ],
            [
              39.865789061,
              21.423344173
            ],
            [
              39.865805859,
              21.423301533
            ],
            [
              39.865829103,
              21.423261635
            ],
            [
              39.865858297,
              21.423225326
            ],
            [
              39.866033593,
              21.423080998
            ],
            [
              39.868409027,
              21.422000208
            ],
            [
              39.869424951,
              21.421550331
            ],
            [
              39.870688569,
              21.421081342
            ],
            [
              39.870773035,
              21.421040016
            ],
            [
              39.871197425,
              21.420832379
            ],
            [
              39.871931498,
              21.42031762
            ],
            [
              39.87198941,
              21.420282062
            ],
            [
              39.872078387,
              21.420227428
            ],
            [
              39.8724021,
              21.420028665
            ],
            [
              39.872748959,
              21.419815688
            ],
            [
              39.87389955,
              21.419109196
            ],
            [
              39.873975741,
              21.419055117
            ],
            [
              39.874076217,
              21.4189838
            ],
            [
              39.874179382,
              21.418910575
            ],
            [
              39.874926865,
              21.418380017
            ],
            [
              39.87579727,
              21.417762201
            ],
            [
              39.875864521,
              21.417714465
            ],
            [
              39.876269295,
              21.417427152
            ],
            [
              39.876826719,
              21.417031482
            ],
            [
              39.877406891,
              21.416619662
            ],
            [
              39.876893427,
              21.415608747
            ],
            [
              39.876876291,
              21.415557935
            ],
            [
              39.876831485,
              21.415425079
            ],
            [
              39.876712003,
              21.41521446
            ],
            [
              39.876633152,
              21.415299113
            ],
            [
              39.876559116,
              21.415387496
            ],
            [
              39.876490094,
              21.415479369
            ],
            [
              39.876426274,
              21.415574483
            ],
            [
              39.87636783,
              21.415672579
            ],
            [
              39.876314919,
              21.415773391
            ],
            [
              39.876267686,
              21.415876647
            ],
            [
              39.87618645,
              21.416106057
            ],
            [
              39.876146426,
              21.416283421
            ],
            [
              39.876082222,
              21.416276815
            ]
          ]
        ]
      }
    },
    "plot": {
      "id": "2/108",
      "name": "12B"
    },
    "company": {
      "id": "b05eae82-1da2-42b3-9789-58e7ddc7b856",
      "name": "المجلس التنسيقي لحجاج الداخل",
      "name_en": "internal company",
      "logo": "https://tafweej-backend-production.firstcity.ai/attachments/companies/logos/21de86db-1efe-4bbd-94a3-0827ae5a4aec.png"
    },
    "office": {
      "id": "344f3872-d7c1-4d93-af59-aab4ce1f9d12",
      "number": 10121,
      "total_pilgrims": 1250,
      "name": "شركة صالح بن غازي الظفيري وشركاه المحدودة"
    },
    "schedule": [
      {
        "batch": {
          "id": "414e8757-bc95-428f-9291-66cb754419fd",
          "code": "INT1012100210807",
          "pilgrim": 122
        },
        "dispatching_day": 10,
        "dispatching_time": "10:40:00",
        "stoning_day": 10,
        "stoning_time": "11:00:00"
      },
      {
        "batch": {
          "id": "dea91716-5767-44cb-8f29-5ce71f825517",
          "code": "INT1012100210806",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "11:40:00",
        "stoning_day": 10,
        "stoning_time": "12:00:00"
      },
      {
        "batch": {
          "id": "960c2db2-3923-43ae-bef1-037b72d5ca63",
          "code": "INT1012100210805",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "18:40:00",
        "stoning_day": 10,
        "stoning_time": "19:00:00"
      },
      {
        "batch": {
          "id": "8faccb8f-429a-4e52-904e-8e2544932052",
          "code": "INT1012100210804",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "13:45:00",
        "stoning_day": 10,
        "stoning_time": "14:05:00"
      },
      {
        "batch": {
          "id": "ac2605a9-97a2-40d6-830f-ec86a13170fc",
          "code": "INT1012100210803",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:50:00",
        "stoning_day": 10,
        "stoning_time": "11:10:00"
      },
      {
        "batch": {
          "id": "54c5b6ff-1e6f-4cb5-b2e7-df15ec5926d8",
          "code": "INT1012100210802",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "09:55:00",
        "stoning_day": 10,
        "stoning_time": "10:15:00"
      },
      {
        "batch": {
          "id": "fc49b663-2ccf-4be2-8e7f-18b8dad38a50",
          "code": "INT1012100210801",
          "pilgrim": 188
        },
        "dispatching_day": 10,
        "dispatching_time": "10:00:00",
        "stoning_day": 10,
        "stoning_time": "10:20:00"
      },
      {
        "batch": {
          "id": "414e8757-bc95-428f-9291-66cb754419fd",
          "code": "INT1012100210807",
          "pilgrim": 122
        },
        "dispatching_day": 11,
        "dispatching_time": "11:40:00",
        "stoning_day": 11,
        "stoning_time": "12:00:00"
      },
      {
        "batch": {
          "id": "dea91716-5767-44cb-8f29-5ce71f825517",
          "code": "INT1012100210806",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "21:40:00",
        "stoning_day": 11,
        "stoning_time": "22:00:00"
      },
      {
        "batch": {
          "id": "960c2db2-3923-43ae-bef1-037b72d5ca63",
          "code": "INT1012100210805",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "16:45:00",
        "stoning_day": 11,
        "stoning_time": "17:05:00"
      },
      {
        "batch": {
          "id": "8faccb8f-429a-4e52-904e-8e2544932052",
          "code": "INT1012100210804",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "10:50:00",
        "stoning_day": 11,
        "stoning_time": "11:10:00"
      },
      {
        "batch": {
          "id": "ac2605a9-97a2-40d6-830f-ec86a13170fc",
          "code": "INT1012100210803",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "22:50:00",
        "stoning_day": 11,
        "stoning_time": "23:10:00"
      },
      {
        "batch": {
          "id": "54c5b6ff-1e6f-4cb5-b2e7-df15ec5926d8",
          "code": "INT1012100210802",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "13:55:00",
        "stoning_day": 11,
        "stoning_time": "14:15:00"
      },
      {
        "batch": {
          "id": "fc49b663-2ccf-4be2-8e7f-18b8dad38a50",
          "code": "INT1012100210801",
          "pilgrim": 188
        },
        "dispatching_day": 11,
        "dispatching_time": "19:55:00",
        "stoning_day": 11,
        "stoning_time": "20:15:00"
      },
      {
        "batch": {
          "id": "414e8757-bc95-428f-9291-66cb754419fd",
          "code": "INT1012100210807",
          "pilgrim": 122
        },
        "dispatching_day": 12,
        "dispatching_time": "16:45:00",
        "stoning_day": 12,
        "stoning_time": "17:05:00"
      },
      {
        "batch": {
          "id": "dea91716-5767-44cb-8f29-5ce71f825517",
          "code": "INT1012100210806",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "06:50:00",
        "stoning_day": 12,
        "stoning_time": "07:10:00"
      },
      {
        "batch": {
          "id": "8faccb8f-429a-4e52-904e-8e2544932052",
          "code": "INT1012100210804",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "18:50:00",
        "stoning_day": 12,
        "stoning_time": "19:10:00"
      },
      {
        "batch": {
          "id": "960c2db2-3923-43ae-bef1-037b72d5ca63",
          "code": "INT1012100210805",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "09:55:00",
        "stoning_day": 12,
        "stoning_time": "10:15:00"
      },
      {
        "batch": {
          "id": "54c5b6ff-1e6f-4cb5-b2e7-df15ec5926d8",
          "code": "INT1012100210802",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "21:55:00",
        "stoning_day": 12,
        "stoning_time": "22:15:00"
      },
      {
        "batch": {
          "id": "ac2605a9-97a2-40d6-830f-ec86a13170fc",
          "code": "INT1012100210803",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "09:00:00",
        "stoning_day": 12,
        "stoning_time": "09:20:00"
      },
      {
        "batch": {
          "id": "fc49b663-2ccf-4be2-8e7f-18b8dad38a50",
          "code": "INT1012100210801",
          "pilgrim": 188
        },
        "dispatching_day": 12,
        "dispatching_time": "11:00:00",
        "stoning_day": 12,
        "stoning_time": "11:20:00"
      },
      {
        "batch": {
          "id": "dea91716-5767-44cb-8f29-5ce71f825517",
          "code": "INT1012100210806",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "07:50:00",
        "stoning_day": 13,
        "stoning_time": "08:10:00"
      },
      {
        "batch": {
          "id": "8faccb8f-429a-4e52-904e-8e2544932052",
          "code": "INT1012100210804",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "12:50:00",
        "stoning_day": 13,
        "stoning_time": "13:10:00"
      },
      {
        "batch": {
          "id": "54c5b6ff-1e6f-4cb5-b2e7-df15ec5926d8",
          "code": "INT1012100210802",
          "pilgrim": 188
        },
        "dispatching_day": 13,
        "dispatching_time": "13:50:00",
        "stoning_day": 13,
        "stoning_time": "14:10:00"
      }
    ]
  }
]
