import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { SafePipe } from './safe.pipe';
import { FilterArrayPipe } from './filterArray.pipe';
import { CountUpDirective } from '../animation-counts/count-up.directive';


@NgModule({
  declarations: [
    FilterPipe,
    SafePipe,
    FilterArrayPipe,
    CountUpDirective
  ],
  imports: [

  ],
  exports: [
    FilterPipe,
    SafePipe,
    FilterArrayPipe,
    CountUpDirective
  ],

})
export class FilterModule { }
