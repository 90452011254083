import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '.';

@Pipe({ name: 'filterArray' })
export class FilterArrayPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} mainArr
   * @param {string} searchText
   * @param {string} property
   * @returns {any}
   */
  transform(items: string[], searchText: string): string[] {
    if (!items || !searchText) {
      return items;
    }
    return items.filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
  }
}
