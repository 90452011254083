import { Component, OnInit } from '@angular/core';
import { main_data } from 'src/app/constent/Route';
import { ImpApiServicesService } from 'src/app/services/imp-api-services.service';

@Component({
  selector: 'app-takamul-services-card',
  templateUrl: './takamul-services-card.component.html',
  styleUrls: ['./takamul-services-card.component.scss']
})
export class TakamulServicesCardComponent implements OnInit {

  constructor(private impApiServicesService: ImpApiServicesService,
  ) { }
  dataList = []
  ngOnInit(): void {
    this.impApiServicesService.get(main_data.integration_takamul.log).subscribe(data => {
      this.dataList = data.data
    })
  }


  downloadFile(pdf) {
    const fileUrl = 'assets/pdf-takamul/' + pdf;
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = pdf;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}
