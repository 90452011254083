import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-permission-services-card',
  templateUrl: './permission-services-card.component.html',
  styleUrls: ['./permission-services-card.component.scss']
})
export class PermissionServicesCardComponent implements OnInit {
  arrayList = []
  constructor(private router: Router,) { }
  user = JSON.parse(localStorage.getItem('user'))
  ngOnInit(): void {


    this.user.components.forEach(element => {
      element.permissions.forEach(element2 => {
        if (element2.code == "PRMS:plot_preference") {
          this.arrayList.push({
            title: element2.name,
            nav: [element.name, element2.name],
            url: '/apps/plot-preferences/list',
            array: [
              'استعراض الخريطة',
              'استعراض المخيمات و تفاصيلها',
              'حجز الرغبة و إزالتها',
              'طباعة الرغبات المحجوزة',
            ]
          })
        }
        if (element2.code == "SCMS:create_jamarat_preference") {
          this.arrayList.push({
            title: element2.name,
            nav: [element.name, element2.name],
            url: '/apps/manage-scheduling-jamarat-preferences/list',
            array: [
              'عرض الرميات',
              'عرض الفترات في كل رمية',
              'ادخال عدد الافواج او الحجاج في الفترة المختارة',
            ]
          })
        }
        if (element2.code == "SCMS:create_train_preference") {
          this.arrayList.push({
            title: element2.name,
            nav: [element.name, element2.name],
            url: '/apps/train-scheduling-preferences/list',
            array: [
              'عرض الحركات',
              'عرض الفترات في كل حركة',
              'ادخال عدد الافواج او الحجاج في الحركة المختارة',
            ]
          })
        }
        if (element2.code == "TMS:create_tickets") {
          this.arrayList.push({
            title: element2.name,
            nav: [element.name, element2.name],
            url: '/apps/ticket-management/list',
            array: [
              'انشاء تذكرة',
              'كتابة تفاصيل الحالة',
              'ارسال التذكرة',
              'عرض تفاصيل التذكرة',
            ]
          })
        }
        if ((element2.code == "SMS:show_surveys_answers")) {
          this.arrayList.push({
            title: element2.name,
            nav: [element.name, element2.name],
            url: '/apps/survey-form/list',
            array: [
              'عرض الاستبيانات المسندة',
              'الاجابة على الاستبيانات',
              'ارسال الاستبيانات',
            ]
          })
        }
        if ((element2.code == "DMS:show_batch_leaders")) {
          this.arrayList.push({
            title: 'إدارة قائدي الأفواج',
            nav: ['إدارة قائدي الأفواج', element2.name],
            url: '/apps/leaders-management/batch-leaders',
            array: [
              'عرض قائدي الأفواج',
              'إنشاء و تعديل قائد الفوج',
              'إسناد الأفواج لقائدي الأفواج',
            ]
          })
        }
        if ((element2.code == "DMS:show_batch_leaders")) {
          this.arrayList.push({
            title: 'إدارة أعضاء المساكن',
            nav: ['إدارة أعضاء المساكن', element2.name],
            url: '/apps/leaders-management/house-leaders',
            array: [
              'عرض أعضاء المساكن',
              'إنشاء و تعديل أعضاء المساكن',
            ]
          })
        }
        if ((element2.code == "DMS:show_batch_leaders")) {
          this.arrayList.push({
            title: 'إدارة مرشدي الحافلات',
            nav: ['إدارة مرشدي الحافلات', element2.name],
            url: '/apps/leaders-management/bus-leaders',
            array: [
              'عرض مرشدي الحافلات',
              'إنشاء و تعديل مرشدي الحافلات',
            ]
          })
        }
        if ((element2.code == "DMS:company_hotels")) {
          this.arrayList.push({
            title: element.name,
            nav: [element.name, element2.name],
            url: '/apps/company-hotels/list',
            array: [
              'عرض المساكن',
              'إنشاء المساكن',
            ]
          })
        }
      });
    });
    this.selectPerm(this.arrayList[0])
  }

  dataPerm = null
  selectPerm(perm) {
    this.dataPerm = perm
  }

  goToNavigate(url) {
    this.router.navigate([url]);

  }

}
