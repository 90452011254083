import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCheckGuard } from './auth/guard/auth-check.guard';
import { CheckTokenGuard } from './auth/guard/check-token.guard';
import { LoginComponent } from './auth/login/login.component';
// import { AdminGuard } from './gaurd-main/admin.guard';
// import { LayoutComponent } from './main/admin/layout/layout.component';
import { MainLayoutComponent } from './main-apps/layout/main-layout/main-layout.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ViewLayoutComponent } from './main-apps/layout/view-layout/view-layout.component';
import { LaunchingPageComponent } from './launching-page/launching-page.component';
import { LaunchingReportPageComponent } from './launching-report-page/launching-report-page.component';
import { PolicyComponent } from './auth/policy/policy.component';
import { QAComponent } from './auth/qa/qa.component';
import { PilgrimFlowsGuard } from './main-apps/apps/pilgrim-flows/pilgrim-flows.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'policy',
    component: PolicyComponent,
  },
  {
    path: 'QA',
    component: QAComponent,
  },
  {
    path: 'launching',
    component: LaunchingPageComponent,
  },
  {
    path: 'launching-report',
    component: LaunchingReportPageComponent,
  },
  {
    path: 'login',
    canActivate: [CheckTokenGuard],
    component: LoginComponent,
  },
  {
    path: 'reset-password',
    canActivate: [CheckTokenGuard],
    component: ResetPasswordComponent,
  },

  {
    path: 'apps', canActivate: [AuthCheckGuard], component: MainLayoutComponent,
    loadChildren: () => import('./main-apps/main-apps.module').then(m => m.MainAppsModule)
  },
  // path for leap
  {
    path: 'apps-v2', canActivate: [AuthCheckGuard], component: ViewLayoutComponent,
    loadChildren: () => import('./main-apps/main-apps.module').then(m => m.MainAppsModule)
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./interactive-dashboard/interactive-dashboard.module').then(m => m.InteractiveDashboardModule)
  },

  {
    path: 'reshuffle-panel',
    canActivate: [AuthCheckGuard],
    loadChildren: () => import('./main-apps/apps/reshuffle-panel/reshuffle-panel.module').then(m => m.ReshufflePanelModule)
  },
  {
    path: 'interactive-map', canActivate: [NgxPermissionsGuard], data: {
      permissions: { only: 'MMS:show_interactive_maps', redirectTo: '/apps/home' }
    },
    loadChildren: () => import('./main-apps/apps/interactive-map-new/interactive-map-new.module').then((m) => m.InteractiveMapNewModule),
  },
  {
    path: 'interactive-map-3d-new', canActivate: [NgxPermissionsGuard], data: {
      permissions: { only: 'MMS:show_interactive_maps', redirectTo: '/apps/home' }
    },
    loadChildren: () => import('./main-apps/apps/interactive-map-3d-new/interactive-map-3d-new.module').then((m) => m.InteractiveMap3dNewModule),
  },
  {
    path: 'interactive-map-4d', canActivate: [NgxPermissionsGuard], data: {
      permissions: { only: 'MMS:show_live_batch_tracking', redirectTo: '/apps/home' }
    },
    loadChildren: () => import('./main-apps/apps/interactive-map-4d/interactive-map-4d.module').then((m) => m.InteractiveMap4dModule),
  },
  {
    path: 'interactive-map-simulation', canActivate: [NgxPermissionsGuard], data: {
      permissions: { only: 'MMS:show_simulation_interactive_maps', redirectTo: '/apps/home' }
    },
    loadChildren: () => import('./main-apps/apps/interactive-map-simulation/interactive-map-simulation.module').then((m) => m.InteractiveMapSimulationModule),
  },
  {
    path: 'interactive-map-poc',
    canActivate: [AuthCheckGuard],
    loadChildren: () => import('./main-apps/apps/interactive-maps-poc/interactive-map-poc.module').then(m => m.InteractiveMapPocModule)
  },
  {
    path: 'mobile-app-page',
    loadChildren: () => import('./mobile-app-landing-page/mobile-app-landing-page.module').then(m => m.MobileAppLandingPageModule)
  },
  {
    path: 'pilgrim-flows',
    canActivate: [PilgrimFlowsGuard],
    loadChildren: () => import('../app/main-apps/apps/pilgrim-flows/pilgrim-flows.module').then((m) => m.PilgrimFlowsModule),
  },
  // {
  //   path: 'powerbi/dashboard',
  //   component: DashboardComponent,
  // },
  // {
  //   path: 'powerbi/kpi-movement',
  //   component: KpiMovementComponent,
  // },
  // {
  //   path: 'powerbi/survey-document',
  //   component: SurveyDocumentComponent,
  // },
  // {
  //   path: 'powerbi/data-tasharkyah',
  //   component: DataTsharkyahComponent,
  // },
  // {
  //   path: 'powerbi/data-gis',
  //   component: DashGisComponent,
  // },

  {
    path: '**',
    redirectTo: 'home',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
