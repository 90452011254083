import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss']
})
export class QAComponent implements OnInit {
  filterList = ""
  icon = environment.icon
  constructor() { }
  role1 = [
    {
      label: '1. ما هي وظيفة التطبيق؟',
      list: [
        'تطبيق التفويج يهدف إلى قياس مدى التزام مراكز الخدمة بجداول التفويج لمنشاة الجمرات في موسم الحج '
      ]
    },
    {
      label: '2. ما هي متطلبات النظام لتشغيل التطبيق؟',
      list: [
        ' يفضل استخدام نسخة حديثة من أنظمة التشغيل (أندرويد 14) أو (iOS 17).'
      ]
    },
    {
      label: '3.	هل يمكنني تقديم ملاحظات أو تقرير عن مشكلة؟',
      list: [
        `نعم عن طريق التواصل مع الدعم الفني عن طريق البريد الالكتروني التالي:    <a href="mailto:IT_SUPPORT@FIRSTCITY.AI" class="Blondie text-black">
        <strong>IT_SUPPORT@FIRSTCITY.AI</strong>
      </a>
         `
      ]
    },
    {
      label: '4.	هل التطبيق مجاني؟ ',
      list: [
        ' نعم مجاني ولا يتطلب اشتراك.'
      ]
    },
    {
      label: '5. كيف يمكنني معرفات متى يتم إصدار تحديث جديد للتطبيق؟',
      list: [
        ' عن طريق الدخول إلى متجر التطبيقات والبحث عن توافر تحديث جديد.'
      ]
    },
    {
      label: '6. كيف يمكنني تحميل التطبيق؟',
      list: [
        ' يمكنك تحميل التطبيق من متجر التطبيقات على هاتفك، سواء كان متجر "جوجل بلاي" لأجهزة أندرويد أو "آب ستور" لأجهزة iOS، بالبحث عن "تطبيق التفويج".'
      ]
    },
    {
      label: '7. هل يتطلب التطبيق الاتصال بالإنترنت للعمل؟',
      list: [
        ' نعم، التطبيق يتطلب الاتصال بالإنترنت لتحديث البيانات والحصول على أحدث جداول التفويج.'
      ]
    },
    {
      label: '8. هل يمكنني استخدام التطبيق بلغات مختلفة؟',
      list: [
        ' لا، فقط اللغة العربية.'
      ]
    },
    {
      label: '9.	ماذا أفعل إذا نسيت كلمة المرور الخاصة بحسابي في التطبيق؟',
      list: [
        ' يمكنك استعادة كلمة المرور عن طريق الضغط على خيار "نسيت كلمة المرور" في صفحة تسجيل الدخول واتباع التعليمات لاستعادة الوصول إلى حسابك.'
      ]
    },
    {
      label: '10.هل يقوم التطبيق بجمع بيانات الموقع الجغرافي في الخلفية؟',
      list: [
        "نعم، يقوم التطبيق بجمع بيانات الموقع الجغرافي عندما يكون التطبيق في الخلفية"
      ]
    },
    {
      label: '11.	ما هي سياسة الخصوصية للتطبيق؟',
      list: [
        ` يمكن الوصول إلى سياسة الخصوصية عن طريق تتبع الرابط التالي:

        <a href="https://tafweej-frontend-dev.firstcity.ai/policy " class="Blondie text-black">
        <strong>https://tafweej-frontend-dev.firstcity.ai/policy </strong>
      </a>
        `
      ]
    },
  ]



  ngOnInit(): void {
  }

}
